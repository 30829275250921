.printHeading {
	display: none;
}

.practiceareas-column-box .print-button {
	margin: 1.5rem 0;
	text-align: center;
}

.practiceareas-column-box .print-button a {
	color: #000 !important;
}

.attorneys-grid-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-left: -10px;
	margin-right: -10px;
}

.attorneys-grid-container .attorney-info {
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 4em;
	display: flex;
	flex-direction: column;
	max-width: 16.66%;
	flex: 0 0 16.66%;
}

.attorneys-grid-container.attorneys-grid-full .attorney-info {
	max-width: 14.285%;
	flex: 0 0 14.285%;
}

.attorneys-grid-container .attorney-info > a {
	border-top: 5px solid #197974;
	background-color: #f3f5f7;
	height: 19vw;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
	display: flex;
	overflow: hidden;
	width: 100%;
	padding-bottom: 112%;
	height: auto;
	overflow: hidden;
	align-items: center;
	border-bottom: 0px !important;
}

.attorneys-grid-container .printAttorneyImage {
	display: none;
}


.attorneys-grid-container .attorney-info > a:hover {
	opacity: 0.85;
}

.attorneys-grid-container .attorney-info div {
	position: relative;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 0;
	font-size: 1.0625rem;
	line-height: 1.5em;
	flex-grow: 1;
	text-align: center;
}

.attorneys-grid-container .attorney-info div a {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	line-height: 1.125em;
	font-weight: 400;
	border-bottom: 0px !important;
}

.attorneys-grid-container .attorney-info div a:hover {
	color: #197974;
}

@media screen and (max-width: 2480px) {
	.attorneys-grid-container .attorney-info {
		max-width: 20%;
		flex: 0 0 20%;
	}
}

@media screen and (max-width: 1980px) {
	.attorneys-grid-container.attorneys-grid-full .attorney-info {
		max-width: 16.66%;
		flex: 0 0 16.66%;
	}

	.attorneys-grid-container .attorney-info {
		max-width: 25%;
		flex: 0 0 25%;
	}
}

@media screen and (max-width: 1550px) {
	.attorneys-grid-container.attorneys-grid-full .attorney-info {
		max-width: 20%;
		flex: 0 0 20%;
	}
}

@media screen and (max-width: 1200px) {
	.attorneys-grid-container.attorneys-grid-full .attorney-info {
		max-width: 25%;
		flex: 0 0 25%;
	}

	.attorneys-grid-container .attorney-info {
		max-width: 33%;
		flex: 0 0 33%;
	}
}

@media screen and (max-width: 991px) {
	.attorneys-grid-container.attorneys-grid-full .attorney-info,
	.attorneys-grid-container .attorney-info {
		max-width: 33.33%;
		flex: 0 0 33.33%;
	}
}


@media screen and (max-width: 767px) {

	.attorneys-grid-container.attorneys-grid-full .attorney-info,
	.attorneys-grid-container .attorney-info {
		max-width: 50%;
		flex: 0 0 50%;
	}

	.attorneys-grid-container .attorney-info > a {
		height: 50vw;
	}

	.attorneys-grid-container .attorney-info div {
		height: 41%;
		padding-bottom: 0;
	}

	.attorneys-grid-container .attorney-info div a {
		line-height: 1.125em;
	}
}

@media screen and (max-width: 479px) {
	.attorneys-grid-container.attorneys-grid-full,
	.attorneys-grid-container {
		flex-direction: column;
	}

	.attorneys-grid-container.attorneys-grid-full .attorney-info,
	.attorneys-grid-container .attorney-info {
		max-width: 300px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.attorneys-grid-container .attorney-info > a {
		height: 80vw;
		min-height: 400px;
	}

	.attorneys-grid-container .attorney-info div {
		width: 100%;
		padding-bottom: 0;
		float: none;
	}
}
