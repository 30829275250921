.slideshow-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 900px;
	margin-top: 0px;
	margin-bottom: 0.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 5rem;
	line-height: 4rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: 0px;
	text-transform: capitalize;
}

.hero-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: 1000px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.slideshow-text {
	display: block;
	width: 100%;
	max-width: 700px;
	margin-top: 1rem;
	margin-bottom: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: Heebo, sans-serif;
	color: #fffefe;
	font-size: 2.25rem;
	line-height: 2.2rem;
	font-style: normal;
	font-weight: 300;
	text-align: left;
	letter-spacing: 0px;
	text-transform: none;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0px;
	margin-left: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0px;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.logo {
	z-index: 100;
	max-width: 250px;
	min-width: auto;
	margin-left: 6rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.navbar {
	top: 0;
	position: fixed;
	display: block;
	width: 100%;
	height: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	background-color: transparent;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 30px;
	padding-right: 20px;
	padding-bottom: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: padding 400ms ease, color 200ms ease;
	transition: padding 400ms ease, color 200ms ease;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.dropdown-toggle:hover {
	color: #fff;
}

.dropdown-toggle.last-dropdown {
	padding-right: 20px;
}

.icon-8 {
	display: none;
}

.dropdown-nav-list {
	background-color: #fff;
}

.dropdown-nav-list.w--open {
	left: -10px;
	right: auto;
	padding: 0rem;
	border-radius: 0px;
	background-color: #444545;
}

.dropdown-nav-link {
	width: 100%;
	padding: 0.85rem 2.25rem 0.85rem 1.25rem;
	border-bottom: 1px none #8d6932;
	border-left: 2px solid transparent;
	-webkit-transition: border-color 400ms ease, background-color 250ms ease;
	transition: border-color 400ms ease, background-color 250ms ease;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 400;
	text-align: left;
}

.dropdown-nav-link:hover {
	width: 100%;
	padding-right: 2rem;
	padding-left: 1.5rem;
	border-bottom: 1px none #fff;
	border-left: 2px solid #fff;
	background-color: transparent;
	color: #fff;
	font-weight: 400;
}

.nav-cover {
	position: absolute;
	left: 0%;
	top: -101%;
	right: 0%;
	bottom: auto;
	z-index: 0;
	width: 100%;
	height: 101%;
	background-color: #c51b00;
	box-shadow: 1px 1px 10px -2px rgba(0, 0, 0, 0.3);
}

.navbar-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.05);
	box-shadow: none;
}

.home-content-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.home-content-section.stats {
	height: auto;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.home-content-section.practice-groups-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	background-color: transparent;
}

.triangle {
	position: absolute;
	left: 1.5rem;
	top: -15px;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 15px;
	border: 1px none #000;
	border-radius: 0px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/gray-dropdown-triangle.svg');
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.section-content {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1450px;
	padding: 6rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.section-content.practice-groups-section {
	padding-top: 6rem;
	padding-bottom: 2rem;
}

.section-content.flexbar-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stats-section {
	display: none;
	width: auto;
	margin-bottom: 2rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.paragraph {
	max-width: 500px;
	margin-bottom: 2.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: left;
	text-decoration: none;
}

.paragraph.first-paragraph {
	max-width: 500px;
	margin-bottom: 1rem;
	text-align: left;
}

.paragraph.centered {
	text-align: center;
}

.paragraph.centered.white {
	margin-bottom: 0rem;
	color: #fff;
}

.subtitle {
	margin-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	color: #ab052d;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.subtitle.news-date {
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding-top: 0rem;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 0.9rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.subtitle.news-date {
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding-top: 0rem;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 0.9rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.footer-section {
	position: relative;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 1px none #e2e2e2;
	background-color: #444545;
}

.footer-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 3px 1.5rem 0px 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	opacity: 1;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: 0px;
	text-transform: none;
	cursor: pointer;
}

.footer-text:hover {
	border-bottom: 3px none #3e85ed;
	opacity: 1;
	color: #fff;
	text-decoration: none;
}

.footer-text.rights {
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0px;
	opacity: 1;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 300;
}

.footer-text.rights h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

.footer-text.rights:hover {
	border-bottom-style: none;
	opacity: 1;
	color: #fff;
}

.footer-text.rights {
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0px;
	opacity: 1;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 300;
}

.footer-text.rights:hover {
	border-bottom-style: none;
	opacity: 1;
	color: #fff;
}

.grip-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: auto;
	padding-bottom: 0.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.footer-column-wrap {
	width: auto;
	min-width: auto;
	margin-right: 0rem;
	padding-right: 0rem;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.footer-image {
	width: 225px;
}

.footer-terms-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
}

.container.footer-container {
	position: static;
	left: 10%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1450px;
	margin-right: 0px;
	margin-left: 0px;
	padding: 2rem 6rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.footer-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-wrapper.top-row {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-wrapper.bottom-row {
	padding-top: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.footer-wrapper.top-row {
	margin-bottom: 1.25rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.2);
}

.footer-column-terms {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 2rem;
	margin-left: 0px;
	padding-bottom: 0.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-column-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 0.5rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.white {
	margin-top: 0rem;
	margin-bottom: 0px;
	background-image: url('/20181016082423/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
	opacity: 1;
}

.footer-nav-list-link {
	display: block;
	margin-top: 0rem;
	margin-right: 1rem;
	padding-left: 0px;
	opacity: 1;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 300;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-nav-list-link:hover {
	opacity: 0.5;
	color: #fff;
}

.footer-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 250px;
	height: 100%;
	margin-right: 0px;
	margin-left: 0rem;
	padding-top: 0rem;
	float: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-bottom-style: none;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.footer-logo:hover {
	color: #fff;
}

.section-heading {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.5rem;
	line-height: 2.75rem;
	font-weight: 300;
	letter-spacing: 0px;
	text-transform: none;
}

.social-wrapper {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 1.5px;
	border-color: #fff;
	border-radius: 100px;
	background-color: #af191f;
	opacity: 1;
	-webkit-transition: all 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block:hover {
	border-style: solid;
	border-color: #444545;
	border-radius: 100px;
	background-color: #444545;
}

.social-media-link-block.twitter {
	width: 45px;
	height: 45px;
	margin-right: 0.5rem;
	border-style: solid;
	border-color: transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block.twitter:hover {
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.social-media-link-block.linkedin {
	width: 45px;
	height: 45px;
	margin-right: 0rem;
	border-style: solid;
	border-color: transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block.linkedin:hover {
	border-style: solid;
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.social-media-link-block.facebook {
	width: 45px;
	height: 45px;
	margin-right: 0.5rem;
	border: 2px solid transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block.facebook:hover {
	border-style: solid;
	border-width: 1.5px;
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.our-firm-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 65%;
	padding-right: 1.3rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.text-link-2 {
	font-family: Heebo, sans-serif;
	color: rgb(68, 69, 69) !important;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.text-link-2:hover {
	color: #8e7043 !important;
}

.text-link-2.link-button-white {
	color: #fff !important;
}

.text-link-2.tertiary-link {
	color: #7a7a7a;
}

.tertiary-button {
	position: relative;
	right: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.tertiary-button.flexbar-link {
	margin-left: 4rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.tertiary-button.flexbar-link-two {
	margin-left: 4rem;
}

.tertiary-button.flexbar-link-two {
	margin-left: 6rem;
}

.tertiary-button.flexbar-link {
	margin-left: 6rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.tertiary-button.about-section {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
}

.tertiary-button.about-section.tertiary {
	padding: 10px;
	background-color: hsla(0, 0%, 94%, 0.7);
}

.tertiary-button.positioning {
	position: absolute;
	left: auto;
	top: auto;
	right: 6rem;
	bottom: auto;
	margin-bottom: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-horiz-line {
	width: 15px;
	height: 2px;
	margin-right: 0.5rem;
	background-color: #b08d57;
}

.link-horiz-line.line-white2 {
	background-color: #fff;
}

.stat-content-wrapper {
	position: relative;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.flexbar-image {
	position: absolute;
	width: 100vw;
	height: 80vh;
	background-image: url('/20181016082423/assets/images/gerold-hinzen-WoZs8gGyQBY-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.flexbar-image._1 {
	left: 6rem;
	right: 6rem;
	z-index: -10;
	display: block;
	overflow: visible;
	width: auto;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._2 {
	left: 6rem;
	right: 6rem;
	z-index: -10;
	display: block;
	overflow: visible;
	width: auto;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._1 {
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._2 {
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._1 {
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-image._2 {
	z-index: -10;
	display: block;
	overflow: visible;
	height: 450px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-col-image {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 80vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1.5px solid rgba(112, 128, 145, 0.25);
	background-color: #222;
	cursor: pointer;
}

.flexbar-col-image:hover {
	background-color: #222;
}

.flexbar-col-image._2 {
	position: absolute;
	right: 0rem;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(rgba(0, 0, 0, 0.55))), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-col-image._1 {
	position: absolute;
	left: 0rem;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(rgba(0, 0, 0, 0.55))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, 1000px;
	background-repeat: repeat, no-repeat;
}

.flexbar-col-image._1:hover {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.flexbar-col-image._1 {
	position: absolute;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.flexbar-col-image._1:hover {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.flexbar-col-image._2 {
	position: absolute;
	z-index: -1;
	display: block;
	width: 100%;
	height: 600px;
	border-right-style: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.projects-section-mobile {
	display: none;
}

.flexbar-col {
	position: absolute;
	left: 50%;
	z-index: -1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 80vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.flexbar-col._1 {
	left: 6rem;
	right: 50%;
	z-index: auto;
	overflow: hidden;
	width: auto;
	height: 450px;
	max-width: none;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.flexbar-col._2 {
	left: 50%;
	right: 6rem;
	z-index: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 450px;
	max-width: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-right: 1px none #969797;
}

.flexbar-col._1 {
	left: 0%;
	z-index: auto;
	overflow: hidden;
	width: 50%;
	height: 450px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.flexbar-col._2 {
	left: 50%;
	z-index: auto;
	overflow: hidden;
	width: 50%;
	height: 450px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-right: 1px none #969797;
}

.flexbar-heading {
	margin-top: 0px;
	margin-bottom: 20px;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 2.75rem;
	line-height: 2.75rem;
	text-align: left;
	letter-spacing: 2px;
	text-transform: none;
}

.flexbar-heading.col {
	margin-bottom: 10px;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2.5rem;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0px;
	text-decoration: none;
}

.flexbar-heading.col._1 {
	margin-right: 6rem;
	margin-bottom: 1rem;
	margin-left: 4rem;
	padding-right: 1rem;
	padding-left: 0rem;
	font-family: Heebo, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 300;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-heading.col._2 {
	margin-bottom: 1rem;
	margin-left: 4rem;
	padding-right: 0rem;
	padding-left: 0rem;
	font-family: Heebo, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 300;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-heading.col._1 {
	margin-right: 6rem;
	margin-bottom: 1rem;
	margin-left: 6rem;
	padding-right: 1rem;
	padding-left: 0rem;
	font-family: Heebo, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 300;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-heading.col._2 {
	margin-bottom: 1rem;
	margin-left: 6rem;
	padding-right: 0rem;
	padding-left: 0rem;
	font-family: Heebo, sans-serif;
	font-size: 2.5rem;
	line-height: 2.5rem;
	font-weight: 300;
	text-align: left;
	text-transform: capitalize;
}

.flexbar-paragraph {
	max-width: 550px;
	margin-bottom: 15px;
	margin-left: 6rem;
	padding-right: 0px;
	padding-left: 0px;
	font-family: Manrope, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
}

.flexbar-paragraph._1 {
	max-width: 500px;
	margin-right: 0rem;
	margin-bottom: 2.5rem;
	margin-left: 4rem;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.flexbar-paragraph._2 {
	max-width: 500px;
	margin-right: 6rem;
	margin-bottom: 2.5rem;
	margin-left: 4rem;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.flexbar-paragraph._2 {
	max-width: 500px;
	margin-right: 6rem;
	margin-bottom: 2.5rem;
	margin-left: 6rem;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.flexbar-paragraph._1 {
	max-width: 500px;
	margin-right: 6rem;
	margin-bottom: 2.5rem;
	margin-left: 6rem;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.flexbar-paragraph._1 {
	max-width: 500px;
	margin-right: 0rem;
	margin-bottom: 2.5rem;
	font-family: Heebo, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.practice-groups-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.practice-group-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33%;
	height: 275px;
	padding: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, scroll;
	background-color: #c3c3c3;
}

.practice-group-cb.one {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 0px;
	display: flex;
}

.practice-group-cb.one:hover {
	border-bottom: 0px;
}

.practice-group-cb.five {
	position: relative;
	display: none;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url('/20181016082423/assets/images/labor-and-employment.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/labor-and-employment.jpg');
	text-decoration: none;
}

.practice-group-cb.four {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url('/20181016082423/assets/images/business-transactions.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/business-transactions.jpg');
	background-position: 0px 0px, 50% 50%;
	text-decoration: none;
}

.practice-group-cb.six {
	position: relative;
	display: none;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url('/20181016082423/assets/images/placeholder.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/placeholder.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, scroll;
	text-decoration: none;
}

.practice-group-cb.two {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url('/20181016082423/assets/images/dan-schiumarini-dcL8ESbsGis-unsplash.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/dan-schiumarini-dcL8ESbsGis-unsplash.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, scroll;
	text-decoration: none;
}

.practice-group-cb.three {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))), url('/20181016082423/assets/images/trucking.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/20181016082423/assets/images/trucking.jpg');
	text-decoration: none;
}

.floating-dot {
	position: absolute;
	left: -5%;
	top: 2rem;
	right: auto;
	bottom: 0%;
	display: none;
	width: 8px;
	height: 8px;
	border-radius: 20px;
	background-color: #c32026;
}

.practice-group-heading {
	position: relative;
	z-index: 10;
	margin-top: 0px;
	margin-bottom: 0rem;
	margin-left: 1rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1.35rem;
	font-weight: 300;
	text-align: center;
}

.hover-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #fff;
	border-radius: 100%;
	background-color: hsla(0, 0%, 100%, 0);
}

.hover-circle-wrapper {
	position: relative;
	display: none;
	max-height: 100%;
	max-width: 100%;
	padding: 60px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.outline-hover-circle {
	position: relative;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 72px;
	height: 72px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: #c32026;
	border-radius: 100%;
	background-color: #c32026;
}

.number-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-bottom: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-slide-image {
	width: 100vh;
	height: 100vh;
	min-width: 100vw;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.hero-slide-image.two {
	height: 100vh;
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, repeat;
}

.hero-slide-image.three {
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, repeat;
}

.hero-slide-image.four {
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.hero-slide-image.five {
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.hero-slider {
	display: block;
	min-height: 100vh;
	background-color: transparent;
	color: #fff;
}

.slide-image-wrapper {
	overflow: hidden;
	width: 100% !important;
	height: 100vh;
}

.hero-slider__arrows {
	display: none;
}

.slide-content {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: none;
	padding-right: 6rem;
	padding-bottom: 8rem;
	padding-left: 6rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.hero-container.option-2 {
	padding-bottom: 8rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.hero__slide-nav {
	left: auto;
	top: 0%;
	right: 2rem;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 12px;
}

.footer-horiz-line {
	display: none;
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: hsla(0, 0%, 100%, 0.24);
}

.slider-arrow {
	left: -100px;
	top: 0px;
	bottom: 80px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 180px;
	height: 180px;
	padding-left: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.3);
	border-radius: 100%;
}

.outline-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	min-height: 42px;
	padding: 16px 32px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(136, 158, 168, 0.2);
	border-radius: 100px;
	-webkit-transition: background-color 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: background-color 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #000;
	font-size: 9px;
	line-height: 1.35em;
	letter-spacing: 3px;
	text-decoration: none;
	text-transform: uppercase;
}

.stat-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.flexbar-images {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 450px;
}

.flexbar-section {
	display: none;
	overflow: hidden;
	width: 100vw;
	height: auto;
	background-color: transparent;
}

.flexbar-725-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 725px;
	min-width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.link-horiz-line-2 {
	width: 15px;
	height: 2px;
	margin-right: 0.5rem;
	background-color: #b08d57;
}

.link-horiz-line-2.white-line {
	background-color: #fff;
}

.link-horiz-line-2.line-white2 {
	display: none;
	background-color: #fff;
}

.link-horiz-line-2.secondary-link-horiz-line {
	background-color: #8d6932;
	color: #444545;
}

.link-horiz-line-2.secondary-link-horiz-line.horix-line-gold {
	background-color: #8d6932;
}

.link-horiz-line-2.secondary-link-horiz-line {
	background-color: #fff;
	color: #444545;
}

.link-text-2 {
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.2rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.link-text-2.white-text-link {
	color: #fff;
	font-weight: 300;
}

.flexbar-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 450px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.practice-groups-paragraph {
	position: relative;
	z-index: 10;
	display: none;
	max-width: 550px;
	margin-bottom: 0px;
	margin-left: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: center;
	text-decoration: none;
}

.slideshow-heading-text-span {
	display: none;
	margin-left: 1rem;
	font-size: 2.5rem;
	line-height: 2rem;
	text-transform: none;
}

.stats-cb {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 500ms ease;
	transition: box-shadow 500ms ease;
	text-decoration: none;
}

.stats-cb:hover {
	border: 1px none #000;
	background-color: transparent;
}

.stats-cb.two {
	margin-right: 5vw;
	margin-left: 5vw;
	padding: 1.25rem 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border: 1px none #000;
}

.stats-cb.two:hover {
	border: 1px none #000;
}

.stats-cb.three {
	display: none;
	border: 1px none #000;
}

.stats-cb.three:hover {
	border-style: none;
}

.stats-cb.four {
	margin-right: 0rem;
	padding: 0rem;
	border-style: none;
	border-color: #000;
}

.stats-cb.four:hover {
	border-style: none;
	border-width: 1px;
}

.stat-icon {
	display: none;
	width: 50px;
	height: 50px;
	margin-right: 0.25rem;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
	background-color: transparent;
}

.stat-icon.offices {
	display: none;
	width: 50px;
	height: 50px;
	margin-right: 0rem;
	margin-bottom: 1rem;
	padding-right: 3px;
	padding-left: 3px;
	border-style: none;
	border-width: 2px;
	border-color: #c51b00;
	border-radius: 0%;
}

.stat-icon.years {
	padding-right: 3px;
	padding-left: 3px;
}

.stat-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #c51b00;
	font-size: 4.5rem;
	line-height: 4rem;
	font-weight: 300;
}

.stat-title {
	max-width: none;
	margin-bottom: 0rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0px;
	text-transform: uppercase;
}

.search-icon {
	display: inline-block;
	margin-top: 5px;
	margin-right: 30px;
	margin-left: 40px;
}

.search-icon.mobile {
	position: relative;
	display: block;
	margin-top: -5px;
	margin-right: 6rem;
	margin-left: 0px;
}

.search-button-icon {
	position: relative;
	z-index: 2;
	width: 10px;
	height: 30px;
	margin-top: 0px;
	border-style: none;
	border-width: 1px;
	border-color: #233e99;
	border-radius: 20px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.search-button-icon:hover {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search-icon-white.svg');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.search-button-icon:active {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.search-content-form {
	position: absolute;
	left: auto;
	top: 87px;
	right: 0%;
	bottom: 0%;
	z-index: 5;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100% !important;
	height: 80px;
	margin: 10px 0px 0px;
	padding: 0px 4rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px none rgba(91, 91, 91, 0.15);
	border-radius: 0px;
	background-color: #fff;
	box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.1);
	display: none;
	opacity: 0;
	height: 80px !important;
	transition: all 200ms ease-in-out;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 22px;
}

.search-input {
	margin-bottom: 0px;
	padding-left: 0px;
	border: 1px none #000;
	background-color: transparent;
	opacity: 1;
	font-family: Heebo, sans-serif;
	color: #000;
	font-size: 0.9rem;
	letter-spacing: 0.5px;
}

.search-button {
	padding: 0rem;
	border-style: none;
	border-width: 2px;
	border-color: #b08d57;
	border-radius: 100px;
	background-color: transparent;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 0.9rem;
	line-height: 1rem;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.search-button:hover {
	border-style: none;
	background-color: transparent;
	color: #b08d57;
}

.exit-button-icon {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 2;
	width: 10px;
	height: 30px;
	margin-top: 0px;
	border-style: none;
	border-width: 1px;
	border-color: #233e99;
	border-radius: 20px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/exit-icon-white.svg');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.exit-button-icon:hover {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/exit-icon-white.svg');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.exit-button-icon:active {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search-icon-white-01.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.search-cb-wrapper {
	position: relative;
}

.exit-cb-wrapper {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
}

.cb-box {
	width: 30%;
	height: auto;
	padding: 2rem;
	border-bottom: 4px solid #d81f03;
	background-color: rgba(63, 63, 63, 0.03);
}

.cb-box.one {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 0rem;
	padding: 1.5rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: solid none none;
	border-width: 1px;
	border-color: hsla(0, 0%, 79.3%, 0.25) rgba(192, 192, 211, 0.4) hsla(0, 0%, 79.3%, 0.25) hsla(0, 0%, 79.3%, 0.25);
	border-radius: 0px;
	background-color: transparent;
	cursor: pointer;
}

.red-triangle {
	position: absolute;
	left: 1.5rem;
	top: -15px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 15px;
	border: 1px none #000;
	border-radius: 0px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/gray-dropdown-triangle.svg');
	background-position: 50% 100%;
	background-size: contain;
	background-repeat: no-repeat;
}

.practice-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.div-block-135 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.practice-group-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	height: 350px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/real-estate.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/real-estate.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.practice-group-col._1 {
	width: 100%;
	height: auto;
	margin-right: 0rem;
	margin-left: 0rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: none;
}

.practice-group-col.heading {
	width: 100%;
	height: auto;
	padding-right: 0rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: none;
}

.image {
	width: 40px;
}

.image-2 {
	width: 50px;
}

.image-3 {
	width: 50px;
}

.absolute-img {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: none;
	width: 38%;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/man-at-desk.jpg');
	background-position: 0% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.col-2 {
	overflow: hidden;
	width: 45%;
	height: 500px;
	margin-left: 0rem;
}

.col-2._2 {
	width: 40%;
	height: 500px;
	background-image: url('/20181016082423/assets/images/open-book.jpg');
	background-position: 0% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.col-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 55%;
	height: auto;
	max-width: none;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.col-1._2 {
	width: 60%;
}

.flexbar-row {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flexbar-row._2 {
	margin-right: 0rem;
	margin-left: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.inner-content-col {
	width: 100%;
	max-width: none;
	padding-left: 3rem;
}

.inner-content-col._1 {
	width: 100%;
	padding-left: 4rem;
}

.inner-content-col._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 4rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.gray-color-block {
	position: absolute;
	left: 0%;
	top: 400px;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	width: 100%;
	height: 535px;
	background-color: #f4f3f3;
}

.col-2-image {
	height: 100%;
	background-image: url('/20181016082423/assets/images/business-man-against-wall.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.col-2-image._2 {
	background-image: url('/20181016082423/assets/images/open-book.jpg');
}

.div-block-141 {
	display: none;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	grid-auto-columns: 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.practice-group-link {
	position: relative;
	right: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px none #f8f9fa;
	border-bottom: 1px solid #f0f0f0;
	text-decoration: none;
}

.practice-group-link.first {
	border-top-style: solid;
	border-top-color: #f0f0f0;
	border-bottom-style: solid;
	border-bottom-color: #f0f0f0;
}

.div-block-142 {
	width: 100%;
}

.div-block-143 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.practice-group-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.read-more-link-2 {
	display: inline;
	border-bottom: 3px none transparent;
	-webkit-transition: background-color 200ms ease, border-color 200ms ease;
	transition: background-color 200ms ease, border-color 200ms ease;
	color: #c41100;
	font-size: 1rem;
	line-height: 1.125rem;
	font-weight: 600;
	text-decoration: none;
}

.read-more-link-2:hover {
	border-bottom: 2px none #b08d57;
	color: #b08d57;
}

.practice-group-cb-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: 0px;
}

.practice-group-cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.practice-group-cb-wrapper.top {
	margin-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.practice-heading {
	margin-top: 0px;
	margin-bottom: 0.25rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 300;
	text-align: left;
	text-decoration: none;
}

.practice-group-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 1.5rem 0rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	border-radius: 0px;
	background-color: transparent;
	outline-color: #333;
	outline-offset: 0px;
	outline-style: none;
	outline-width: 3px;
}

.paragraph-3 {
	max-width: 500px;
	margin-bottom: 2.5rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-decoration: none;
}

.paragraph-3.news {
	margin-bottom: 1.5rem;
	font-weight: 300;
}

.practice-cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33%;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px none #000;
	border-radius: 5px;
	box-shadow: none;
	text-decoration: none;
	cursor: pointer;
}

.practice-cb.three {
	width: 30%;
}

.practice-cb.one {
	width: 30%;
	margin-bottom: 2rem;
}

.practice-cb.two {
	width: 30%;
	margin-top: 0px;
	margin-right: 2.5rem;
	margin-left: 2.5rem;
}

.practice-cb.four {
	width: 30%;
	margin-bottom: 2rem;
}

.cb-image {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 225px;
	margin-bottom: 1rem;
	background-image: url('/20181016082423/assets/images/thisisengineering-raeng-jqD8bSQY6Ic-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.cb-image._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20181016082423/assets/images/business-transactions.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.cb-image._3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20181016082423/assets/images/trucking.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.cb-image._1 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
}

.cb-image._4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.cb-image._5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/labor-and-employment.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/labor-and-employment.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.cb-image._6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 225px;
	margin-bottom: 1rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('/20181016082423/assets/images/slideshow-3.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/20181016082423/assets/images/slideshow-3.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.news-btn-text {
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 300;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.hover-circle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.real-estate {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/real-estate_1.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/real-estate_1.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.prof-liability {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/professional-liability.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/professional-liability.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.trucking {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/trucking.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/trucking.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.bus-transactions {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/business-transactions_1.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/business-transactions_1.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.employment-labor {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/labor-and-employment.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/labor-and-employment.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.intel-property {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20181016082423/assets/images/placeholder.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20181016082423/assets/images/placeholder.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.stat-icon-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin-right: 0.25rem;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100px;
	background-color: transparent;
}

.stat-icon-2.years {
	padding-right: 3px;
	padding-left: 3px;
}

.stat-icon-2.offices {
	margin-right: 0.25rem;
	padding-right: 3px;
	padding-left: 3px;
}

.stat-number-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding: 0.5rem 0rem 0.5rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #c41100;
	font-size: 3.25rem;
	line-height: 3.25rem;
	font-weight: 400;
}

.stats-section-2 {
	display: -ms-grid;
	display: grid;
	width: 40%;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: stretch;
	-webkit-justify-content: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	justify-items: stretch;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.stats-cb-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: box-shadow 500ms ease;
	transition: box-shadow 500ms ease;
	text-decoration: none;
}

.stats-cb-2:hover {
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
	background-color: transparent;
}

.stats-cb-2.two {
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 1.25rem 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-style: solid none;
	border-width: 1px;
	border-color: #e2e2e2 #000;
}

.stats-cb-2.three {
	display: none;
	border: 1px none #000;
}

.stats-cb-2.three:hover {
	border-style: none;
}

.stats-cb-2.four {
	margin-right: 0rem;
	padding: 1.25rem 0rem;
	border-right-style: none;
	border-bottom-style: solid;
}

.stats-cb-2.four:hover {
	border-style: solid none;
}

.stat-content-wrapper-2 {
	position: relative;
	z-index: 10;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stat-text-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.number-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.stat-title-2 {
	width: 50%;
	max-width: none;
	margin-bottom: 0rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1.125rem;
	line-height: 1.15rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0px;
	text-transform: none;
}

.secondary-button1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.secondary-button1.flexbar-link-two {
	margin-left: 6rem;
}

.secondary-button1.flexbar-link {
	margin-left: 6rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.secondary-button1.white-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
	background-color: transparent;
	text-align: left;
}

.secondary-button1.white-link:hover {
	border-style: none;
	background-color: transparent;
}

.secondary-button1.white-link.tertiatry {
	background-color: rgba(0, 0, 0, 0.1);
}

.secondary-button1.white-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 4rem;
	padding: 10px;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
	background-color: transparent;
}

.secondary-button1.white-link:hover {
	border-style: none;
	background-color: transparent;
}

.secondary-button1.white-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1rem;
	padding: 10px;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
	background-color: transparent;
}

.secondary-button1.white-link:hover {
	border-style: none;
	background-color: transparent;
}

.secondary-button1.white-link.secondary-invisible {
	display: none;
}

.flexbar-section-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100vw;
	height: auto;
	background-color: transparent;
}

.home-content-section-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.section-heading-2 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.5rem;
	line-height: 2.75rem;
	font-weight: 300;
}

.section-heading-2.practice-groups {
	width: auto;
	margin-right: auto;
	margin-left: auto;
	color: #335962;
	text-align: left;
}

.group-slide {
	height: 100%;
	margin-right: 0rem;
}

.right-arrow {
	left: auto;
	top: -85px;
	right: 25.5vw;
	bottom: auto;
	display: none;
	width: auto;
	height: auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #c32026;
}

.right-arrow:hover {
	-webkit-transform: translate(8px, 0px);
	-ms-transform: translate(8px, 0px);
	transform: translate(8px, 0px);
}

.practice-groups-slider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100vw;
	height: auto;
	margin-left: 0px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
}

.slide-nav {
	display: none;
}

.left-arrow {
	left: auto;
	top: -85px;
	right: 28.5vw;
	bottom: auto;
	display: none;
	width: auto;
	height: auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #c32026;
}

.left-arrow:hover {
	-webkit-transform: translate(-8px, 0px);
	-ms-transform: translate(-8px, 0px);
	transform: translate(-8px, 0px);
}

.right-arrow-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.home-content-section-3 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.home-content-section-3.practice-groups-section {
	overflow: hidden;
	background-color: #fff;
}

.practice-group-heading-2 {
	position: relative;
	z-index: 10;
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.75rem;
	font-weight: 300;
	text-align: center;
}

.practice-groups-slider-mask {
	overflow: visible;
	width: 25%;
	height: 620px;
	padding-left: 0rem;
}

.heading-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	padding-bottom: 1rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-section.practice-groups {
	max-width: 1450px;
	padding-right: 6rem;
	padding-bottom: 2rem;
	padding-left: 6rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.circle-hover {
	position: absolute;
	left: auto;
	top: -6vw;
	right: -6vw;
	bottom: auto;
	width: 6vw;
	height: 6vw;
	border-radius: 100%;
	background-color: #2a2c34;
}

.circle-hover.white {
	top: -9vw;
	right: -6vw;
	z-index: 0;
	background-color: rgba(196, 17, 0, 0.85);
	width: 6vw !important;
	height: 6vw !important;
	transform: translate3d(3vw, -3vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
	transform-style: preserve-3d !important;
	transition: all 1s ease;
}

.practice-group-cb.one:hover .circle-hover.white {
	width: 70vw !important;
	height: 70vw !important;
	transform: translate3d(3vw, -3vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
	transform-style: preserve-3d !important;
}

.practice-group-cb.one:hover .practice-group-paragraph-2-wrap {
	display: block;
	opacity: 1;
}

.section-content-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1450px;
	padding: 4rem 6rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
}

.section-content-2.practice-groups-section {
	overflow: visible;
	max-width: none;
	padding: 4rem 0rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-arrow-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.practice-groups-paragraph-2 {
	position: relative;
	z-index: 10;
	height: auto;
	max-width: 550px;
	margin-bottom: 1.5rem;
	margin-left: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: center;
	text-decoration: none;
}

.practice-group-paragraph-2-wrap {
	position: relative;
	z-index: 10;
	display: block;
	max-width: 550px;
}

.text-link {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
}

.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.table-div {
	font-family: Heebo, sans-serif;
	font-weight: 300;
}

.paragraph-4 {
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	text-decoration: none;
}

.paragraph-4.list-item {
	margin-bottom: 0rem;
}

.breadcrumbs-wrapper {
	display: block;
	max-width: 1500px;
	margin-bottom: 30px;
	padding-left: 0rem;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumbs-wrapper.old-breadcrump {
	display: none;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.unordered-list {
	margin-bottom: 1rem;
}

.primary-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-left: -20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: -webkit-transform 400ms ease;
	transition: -webkit-transform 400ms ease;
	transition: transform 400ms ease;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	cursor: pointer;
}

.primary-button:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.primary-button.inside-page {
	margin-top: 0px;
	margin-right: 4rem;
	margin-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.primary-button.inside-page {
	display: block;
	margin-top: 0px;
	margin-right: 2rem;
	margin-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.child-wrap {
	padding-bottom: 0px;
	padding-left: 0rem;
	border-bottom: 1px none #000;
}

.paragraph-5 {
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 16px;
	line-height: 23px;
	font-weight: 300;
}

.left-nav-section-title-link {
	display: block;
	padding: 0.75rem 0px 0.75rem 0.5rem;
	border-style: solid none;
	border-width: 1px;
	border-color: rgba(25, 121, 116, 0.2) rgba(51, 51, 51, 0.2);
	background-color: transparent;
	color: #335962;
	font-size: 2rem;
	line-height: 2.4rem;
	font-weight: 300;
	text-decoration: none;
}

.left-nav-link-3 {
	display: block;
	width: 100%;
	padding: 15px 0.125rem 15px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3:hover {
	padding-right: 0rem;
	padding-left: 0.5rem;
	border-left: 4px solid #197974;
	color: #197974;
}

.left-nav-link-3.w--current {
	padding: 15px 0.5rem;
	border-style: none solid solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 106, 181, 0.19);
	border-radius: 0px;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #0a560c;
	text-transform: none;
}

.left-nav-link-3.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.grandchild-link {
	padding: 15px 0.125rem 15px 2rem;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-style: none;
	border-bottom-color: rgba(25, 121, 116, 0.2);
	border-left-style: none;
	border-left-color: rgba(51, 51, 51, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #335962;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	border-left-color: rgba(138, 176, 221, 0.5);
	color: #197974;
}

.left-nav-link-3.grandchild-link.w--current {
	padding-top: 15px;
	padding-bottom: 15px;
	border-left: 1px solid rgba(51, 51, 51, 0.2);
	background-image: none;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link {
	width: auto;
	padding: 15px 0.125rem 15px 3rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: Heebo, sans-serif;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	border-left: 4px solid #197974;
	color: #197974;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	margin-right: 0rem;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 3rem;
	border-left: 1px solid rgba(51, 51, 51, 0.2);
	border-bottom-style: solid;
	border-bottom-color: rgba(25, 106, 181, 0.19);
	background-image: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #0a560c;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 600;
}

.inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.inside-page-container.white-background {
	position: relative;
	width: auto;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
}

.inside-page-container.white-background {
	position: relative;
	width: auto;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
}

.inside-page-container.white-background {
	position: relative;
	width: 100%;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
}

.heading-9 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 300;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #213a7f;
	font-size: 1rem;
}

.underline-image {
	margin-top: -15px;
	margin-left: 10px;
	padding-top: 0px;
}

.underline-image.blue {
	margin-left: 10px;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.breadcrumbs-list {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	margin-bottom: 3rem;
	padding-bottom: 0rem;
}

.content-container.threequarterwidth {
	position: relative;
	top: -48px;
	width: 100%;
	height: auto;
	margin-bottom: 0rem;
	padding-bottom: 0rem;
	min-height: 700px;
}

.heading-10 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 500;
	text-transform: uppercase;
}

.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #197974;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.button-link {
	margin-top: 40px;
	padding-top: 0px;
	border-bottom: 4px none #000;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	white-space: normal;
	cursor: pointer;
}

.button-link.two {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 40px;
	margin-left: 0px;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: -webkit-transform 400ms ease;
	transition: -webkit-transform 400ms ease;
	transition: transform 400ms ease;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	color: #238425;
	font-weight: 800;
}

.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.h4-header-link:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #289993;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(51, 51, 51, 0.2);
	color: #197974;
}

.secondary-button-style {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.breadcrumb-link {
	border-bottom: 1px none #196ab5;
	opacity: 0.69;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: underline;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	opacity: 1;
	color: #335962;
	text-decoration: none;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	opacity: 1;
	color: #335962;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #196b19;
}

.left-nav-wrapper {
	overflow: hidden;
	width: 30%;
	height: 750px;
	margin-right: 0rem;
	padding: 20px 40px;
	border-style: solid none none;
	border-width: 8px 1px 1px;
	border-color: #197974 rgba(138, 176, 221, 0.15) rgba(138, 176, 221, 0.15);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #289993;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
}

.image-13 {
	width: 100%;
	margin-bottom: 20px;
}

.content-button {
	padding: 10px 20px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(210, 210, 210, 0.81);
	border-radius: 0px;
	background-color: #ca4949;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 26px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: 2px;
}

.content-button:hover {
	border-color: #238425;
	background-color: #238425;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.left-nav-section-title {
	margin-top: 0px;
	margin-bottom: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
	line-height: 50px;
	font-weight: 400;
	text-transform: none;
}

.secondary-button {
	margin-right: 20px;
	margin-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(51, 51, 51, 0.2);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #238425;
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	letter-spacing: 0px;
	text-transform: uppercase;
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 450px;
	background-color: #c9c6c4;
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.breadcrumbs-list-item {
	display: inline-block;
	font-family: Heebo, sans-serif;
	color: #333;
}

.heading-2 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 46px;
	line-height: 45px;
	font-weight: 300;
	text-transform: none;
}

.ordered-list {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.inside-page-content {
	position: relative;
	z-index: 0;
	display: block;
	margin-top: 0px;
	margin-right: 0%;
	margin-left: 0%;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: none;
}

.inside-hero-section {
	padding-top: 3rem;
	padding-right: 6rem;
	padding-left: 6rem;
	background-image: url('/20181016082423/assets/images/heyl-royster-red-logo-light.svg'), none;
	background-position: 50% 90%, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat-x, repeat;
	background-attachment: fixed, fixed;
	font-family: Heebo, sans-serif;
}

.div-block-144 {
	position: relative;
	top: -96px;
	bottom: auto;
	z-index: 999;
	padding: 10px 40px 0px;
	border-top: 8px solid #197974;
	background-color: #fff;
}

.cookie-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	min-width: 425px;
	padding-top: 30px;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cookie-text-para {
	width: 66.66%;
	padding-right: 1rem;
	padding-left: 1rem;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.container-2 {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.container-2.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container-2.footer.gdrp {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.gdrp-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 2rem;
	color: #bdbdbd;
	text-align: center;
}

.gdrp-link:hover {
	color: #a1866c;
}

.text-link-3 {
	font-family: Heebo, sans-serif;
	color: #b08d57;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.text-link-3.link-button-white {
	display: none;
	color: #fff;
}

.text-link-3.tertiary-link {
	color: #7a7a7a;
}

.text-link-3.tertiary-link.secondary-button-link {
	color: #7a7a7a;
}

.text-link-3.tertiary-link.secondary-button-link.button-golden {
	color: #444545;
}

.text-link-3.tertiary-link.secondary-button-link {
	color: #fff;
}

.secondary-button2 {
	position: relative;
	right: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
}

.secondary-button2.about-section {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-style: none;
	border-width: 1px;
	border-color: #b08d57;
}

.secondary-button2.about-section.tertiary {
	padding: 10px;
	background-color: hsla(0, 0%, 94%, 0.7);
}

.secondary-button2.about-section.tertiary.secondary-button1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	border-style: none;
	border-color: #b08d57;
	background-color: transparent;
	color: #fff;
}

.secondary-button2.about-section.tertiary.secondary-button1 {
	margin-right: 0rem;
	border-style: solid;
	border-color: #b08d57;
	background-color: #b08d57;
	color: #fff;
}

.secondary-button2.about-section.tertiary.secondary-button1 {
	margin-right: 0rem;
	border-style: none;
	border-color: #b08d57;
	background-color: transparent;
	color: #fff;
}

.secondary-button2.about-section.tertiary.secondary-button1 {
	margin-right: 2rem;
	border-style: solid;
	border-color: #b08d57;
	background-color: transparent;
	color: #fff;
}

.gdrp-section {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: none;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #000;
	box-shadow: none;
}

.exit-icon-div {
	position: absolute;
	left: auto;
	top: 1rem;
	right: 1rem;
	bottom: auto;
	width: 30px;
	height: 30px;
	border-bottom-style: none;
	background-image: url('/20181016082423/assets/images/close-icon-lt-grey.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.65;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	text-decoration: none;
}

.exit-icon-div:hover {
	opacity: 1;
}

.gdrp-section-icon {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #fff;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 26%;
	margin-right: 0vw;
	margin-left: 75vw;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
	box-shadow: none;
	opacity: 1;
}

.open-gdrp-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin-right: 4rem;
	padding-right: 0px;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	border-bottom-style: none;
	background-image: url('/20181016082423/assets/images/Gear-Icon-Brown-08.svg');
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition-property: none;
	transition-property: none;
	text-decoration: none;
}

.open-gdrp-icon:hover {
	background-image: url('/20181016082423/assets/images/Gear-Icon-Black-08.svg');
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.breadcrumb-link-2 {
	border-bottom: 1px none #196ab5;
	opacity: 0.69;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: underline;
	cursor: pointer;
}

.breadcrumb-link-2:hover {
	border-bottom-color: transparent;
	opacity: 1;
	color: #335962;
	text-decoration: none;
}

.breadcrumb-link-2.current-bc-link {
	border-bottom-style: none;
	opacity: 1;
	color: #335962;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link-2.current-bc-link:hover {
	color: #196b19;
}

.left-nav-section-title-link-2 {
	display: block;
	padding: 0.5rem 0px 0.5rem 0.5rem;
	border-style: solid none;
	border-width: 1px !important;
	border-color: rgba(25, 121, 116, 0.2) rgba(51, 51, 51, 0.2) !important;
	background-color: transparent;
	color: #335962;
	font-size: 2rem;
	line-height: 2.4rem;
	font-weight: 300;
	text-decoration: none;
}

.inside-page-content-2 {
	position: relative;
	z-index: 0;
	display: block;
	margin-top: 0px;
	margin-right: 0%;
	margin-left: 0%;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: none;
}

.left-nav-link-4 {
	display: block;
	width: auto;
	padding: 10px 0rem 10px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-4:hover {
	padding-right: 0rem;
	padding-left: 0.5rem;
	border-left: 4px solid #197974;
	color: #197974;
}

.left-nav-link-4.w--current {
	padding: 15px 0.5rem;
	border-style: none solid solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 106, 181, 0.19);
	border-radius: 0px;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #0a560c;
	text-transform: none;
}

.left-nav-link-4.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-4.greatgrandchild-link {
	width: auto;
	padding: 10px 0rem 10px 3rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: Heebo, sans-serif;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-4.greatgrandchild-link:hover {
	border-left: 4px solid #197974;
	color: #197974;
}

.left-nav-link-4.greatgrandchild-link.w--current {
	margin-right: 0rem;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 3rem;
	border-left: 1px solid rgba(51, 51, 51, 0.2);
	border-bottom-style: solid;
	border-bottom-color: rgba(25, 106, 181, 0.19);
	background-image: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #0a560c;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 600;
}

.left-nav-link-4.grandchild-link {
	width: auto;
	padding: 10px 0rem 10px 2rem;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-style: none;
	border-bottom-color: rgba(25, 121, 116, 0.2);
	border-left-style: none;
	border-left-color: rgba(51, 51, 51, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #335962;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-4.grandchild-link:hover {
	border-left-color: rgba(138, 176, 221, 0.5);
	color: #197974;
}

.left-nav-link-4.grandchild-link.w--current {
	padding-top: 15px;
	padding-bottom: 15px;
	border-left: 1px solid rgba(51, 51, 51, 0.2);
	background-image: none;
	font-weight: 600;
}

.h5-header-link-2 {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.h5-header-link-2:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #289993;
}

.inside-hero-section-2 {
	padding-top: 0rem;
	padding-right: 6rem;
	padding-left: 6rem;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.05))), url('/20181016082423/assets/images/Desktop---1-15.svg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/Desktop---1-15.svg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
	background-attachment: scroll, fixed;
	font-family: Heebo, sans-serif;
}

.h4-header-link-2 {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.h4-header-link-2:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.heading-11 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 500;
	text-transform: uppercase;
}

.breadcrumbs-wrapper-2 {
	display: block;
	max-width: 1500px;
	margin-bottom: 30px;
	padding-left: 0rem;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumbs-wrapper-2.old-breadcrump {
	display: none;
}

.heading-12 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	font-weight: 300;
}

.div-block-145 {
	position: relative;
	top: -30px;
	bottom: auto;
	z-index: 999;
	padding: 30px 40px 0px;
	border-top: 8px solid #335962;
	background-color: #fff;
}

.button-styling-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #444545;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.h6-header-link-2 {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.h6-header-link-2:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.h3-header-link-2 {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.h3-header-link-2:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.left-nav-wrapper-2 {
	overflow: hidden;
	width: 30%;
	height: auto;
	min-width: 200px;
	margin-right: 0rem;
	margin-bottom: 3rem;
	padding: 20px 0px 0px 20px;
	border-style: solid none none;
	border-width: 8px 1px 1px;
	border-color: #335962 rgba(138, 176, 221, 0.15) rgba(138, 176, 221, 0.15);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-color: #335962;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
}

.h2-header-link-2 {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
}

.h2-header-link-2:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.unordered-list-2 {
	margin-bottom: 1rem;
	list-style-type: disc;
}

.horiz-divider-2 {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(51, 51, 51, 0.2);
	color: #197974;
}

.inside-page-header-section-2 {
	position: relative;
	display: block;
	height: 450px;
	background-color: #b08d57;
	background-position: 0px 0px, 0px 0px, 100% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.block-quote-2 {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #335962;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.text-link-4 {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.text-link-4:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.heading-13 {
	margin-top: 0px;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 46px;
	line-height: 45px;
	font-weight: 300;
	text-transform: none;
}

.footer-nav-list-link-2 {
	display: block;
	margin-top: 0rem;
	margin-right: 1rem;
	padding-left: 0px;
	opacity: 1;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 300;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-nav-list-link-2:hover {
	opacity: 0.5;
	color: #fff;
}

.footer-section-2 {
	position: relative;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 1px none #e2e2e2;
	background-color: #444545;
}

.social-media-link-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 1.5px;
	border-color: #fff;
	border-radius: 100px;
	background-color: #af191f;
	opacity: 1;
	-webkit-transition: all 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 250ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.social-media-link-block-2:hover {
	border-style: solid;
	border-color: #444545;
	border-radius: 100px;
	background-color: #444545;
}

.social-media-link-block-2.facebook {
	width: 45px;
	height: 45px;
	margin-right: 0.5rem;
	border: 2px solid transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block-2.facebook:hover {
	border-style: solid;
	border-width: 1.5px;
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.social-media-link-block-2.linkedin {
	width: 45px;
	height: 45px;
	margin-right: 0rem;
	border-style: solid;
	border-color: transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block-2.linkedin:hover {
	border-style: solid;
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.social-media-link-block-2.twitter {
	width: 45px;
	height: 45px;
	margin-right: 0.5rem;
	border-style: solid;
	border-color: transparent;
	background-color: hsla(0, 0%, 100%, 0.05);
	opacity: 1;
}

.social-media-link-block-2.twitter:hover {
	border-color: #b08d57;
	background-color: #b08d57;
	opacity: 1;
}

.left-nav-link-5 {
	display: block;
	width: 100%;
	padding: 10px 0rem 10px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-5:hover {
	padding-right: 0rem;
	padding-left: 0.5rem;
	border-left: 4px solid #335962;
	color: #197974;
}

.left-nav-link-5.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-5.greatgrandchild-link {
	width: auto;
	padding: 10px 0rem 10px 3rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(25, 121, 116, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: Heebo, sans-serif;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-5.greatgrandchild-link:hover {
	border-left: 4px solid #335962;
	color: #197974;
}

.left-nav-link-5.grandchild-link {
	padding: 10px 0rem 10px 2rem;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-style: none;
	border-bottom-color: rgba(25, 121, 116, 0.2);
	border-left-style: none;
	border-left-color: rgba(51, 51, 51, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #335962;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-5.grandchild-link:hover {
	border-left-color: rgba(138, 176, 221, 0.5);
	color: #197974;
}

.tertiary-button-wrap {
	margin-right: 2rem;
}

.sidebar-list {
	padding-left: 0em;
}

.paragraph-6 {
	font-weight: 300;
}

.paragraph-7 {
	font-family: Heebo, sans-serif;
	font-weight: 300;
}

.text-link-5 {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.text-link-5:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
	color: #213a7f;
}

.sidebar-list-item:hover {
	color: #4a73bc;
}

.secondary-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 2rem;
	margin-left: 0px;
	padding: 1rem 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.screen-message-text {
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 300;
}

.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 400;
}

.right-nav {
	width: auto;
	max-width: 21%;
	margin-bottom: 3rem;
	padding-top: 3rem;
	padding-right: 20px;
	padding-left: 20px;
	border-top: 8px solid #335962;
	background-color: #fff;
	font-family: Heebo, sans-serif;
	font-style: normal;
	min-width: 210px;
}

.inside-page-content-left-right {
	position: relative;
	z-index: 0;
	display: block;
	margin-top: 0px;
	margin-right: 0%;
	margin-left: 0%;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: none;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

.link-3 {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	text-decoration: none;
}

.link-3:hover {
	border-bottom: 0px none rgba(156, 203, 60, 0);
	color: #197974;
}

.link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.button-styling-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.button-styling-4 {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.button-styling-4._0 {
	background-color: transparent;
}

.secondary-button-wrap-2 {
	margin-right: 2rem;
	margin-left: 0px;
	padding: 1rem 0rem;
}

#events_module.cms_entity .event-categories-wrapper {
	margin-bottom: 10px;
}

#events_module.cms_entity .events-detail-item.category-label {
	font-size: 0.975em;
}

#events_module.cms_entity .event-attorneys-wrapper {
	display: flex;
	gap: 0px 0.2rem;
}


#events_module.cms_entity .event-attorneys-label {
	display: inline-block;
	color: #555;
	overflow: hidden;
	margin-right: 0.5rem;
}

#events_module.cms_entity .events-detail-item.attorney-label {
	overflow: hidden;
	border-bottom: 0 none;
	display: inline-block;
	font-size: 0.975em;
	line-height: 1.55em;
}

#events_module.cms_entity .attorney-link-divider {
	overflow: hidden;
	border-bottom: 0 none;
	margin-left: 0px;
	margin-right: 3px;
	display: inline-block;
	font-size: 0.875em;
	line-height: 1.55em;
}

#events_module.cms_entity .events-detail-item.attorney-label .attorney-link-text {
	float: left;
}

.mini-menu-dropdown {
	margin-bottom: 30px;
	display: none;
}

.mini-menu-dropdown .mini-menu-dropdown-toggle {
	background-image: url('/20181016082423/assets/images/white-arrow-down.png/');
	background-repeat: no-repeat;
	background-position: 98% center;
	background-size: 16px;
	width: 100%;
	background-color: #c51b00;
	color: #fff;
	text-align: left;
	padding: 10px 12px;
	font-size: 1rem;
}

.mini-menu-dropdown .mini-menu-dropdown-list {
	display: none;
	background-color: #efefef;
	padding: 10px 20px;
}

.mini-menu-dropdown .mini-menu-dropdown-list .left-nav-section-title {
	display: none;
}

.mini-menu-dropdown .mini-menu-dropdown-list .left-nav-link-5 {
	border-bottom: transparent;
}

.mini-menu-dropdown .left-nav-link-5:hover {
	color: #197974;
}

.mini-menu-dropdown .left-nav-link-5.greatgrandchild-link {
	padding-top: 5px;
	padding-bottom: 5px;
}

.mini-menu-dropdown .left-nav-link-5.greatgrandchild-link:hover {
	border-left: 0px;
}

@media screen and (max-width: 991px) {
	.mini-menu-dropdown {
		display: block;
	}

	.practice-group-cb-4 {
		opacity: 1 !important;
	}

	.slideshow-heading {
		max-width: none;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		font-size: 4.5rem;
		line-height: 3.25rem;
	}

	.hero-content {
		width: 100%;
	}

	.slideshow-text {
		max-width: 500px;
		margin-bottom: 0px;
		font-size: 1.3rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		font-family: Montserrat, sans-serif;
		color: #fff;
		font-weight: 500;
	}

	.mobile-arrow:hover {
		color: #b08d57;
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 20px 3rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
		-webkit-transition: color 200ms ease;
		transition: color 200ms ease;
		font-family: Heebo, sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1rem;
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		cursor: pointer;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: transparent;
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #fff;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.nav-menu {
		position: absolute;
		left: auto;
		right: auto;
		display: block;
		width: 100%;
		margin: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		background-color: #444545;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top: 1px none #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
	}

	.two-part-mobile-nav-wrapper.top {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-left: 5px none transparent;
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: none;
		border-bottom-color: rgba(77, 76, 76, 0.25);
		-webkit-transition: color 200ms ease;
		transition: color 200ms ease;
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		border-left: 4px solid #fff;
		background-color: transparent;
		color: #fff;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px none rgba(77, 76, 76, 0.25);
		background-color: transparent;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: transparent;
		color: #fff;
	}

	.nav-wrapper {
		display: none;
	}

	.logo {
		max-width: 200px;
		margin-left: 3rem;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.navbar-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		background-color: transparent;
	}

	.section-content {
		padding: 3rem 3rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.section-content.practice-groups-section {
		padding-top: 3rem;
	}

	.stats-section {
		width: 100%;
		margin-bottom: 2.5rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.paragraph {
		max-width: none;
	}

	.paragraph.first-paragraph {
		max-width: none;
	}

	.footer-text {
		margin-bottom: 0px;
	}

	.grip-link {
		width: 25%;
		padding-right: 0vw;
		padding-bottom: 0rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-column-wrap {
		width: 50%;
		min-width: 0px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding-bottom: 0rem;
	}

	.footer-terms-container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.footer-container {
		left: 5%;
		padding: 1rem 3rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-wrapper {
		width: 100%;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px none rgba(250, 251, 252, 0.05);
	}

	.footer-wrapper.top-row {
		padding-bottom: 0rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-wrapper.bottom-row {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-wrapper.top-row {
		padding-bottom: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-column-terms {
		width: 75%;
		padding-left: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-column-links {
		width: 75%;
		padding-left: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-nav-list-link {
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.footer-logo {
		display: block;
		width: auto;
		height: auto;
		margin: 0rem 0px;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.social-media-link-block {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block:hover {
		border-radius: 0%;
	}

	.social-media-link-block.twitter:hover {
		border-radius: 100px;
	}

	.social-media-link-block.linkedin:hover {
		border-color: #b08d57;
		border-radius: 100px;
	}

	.social-media-link-block.facebook:hover {
		border-radius: 100px;
	}

	.our-firm-section {
		width: 100%;
		margin-bottom: 2rem;
		padding-right: 0rem;
	}

	.tertiary-button.flexbar-link {
		margin-left: 3rem;
	}

	.tertiary-button.flexbar-link-two {
		margin-left: 3rem;
	}

	.tertiary-button.flexbar-link-two {
		margin-left: 3rem;
	}

	.tertiary-button.flexbar-link {
		margin-left: 3rem;
	}

	.tertiary-button.positioning {
		position: relative;
		right: auto;
	}

	.stat-content-wrapper {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.flexbar-col-image._2 {
		width: 50%;
		height: 400px;
	}

	.flexbar-col-image._1 {
		width: 50%;
		height: 400px;
	}

	.flexbar-col-image._1 {
		width: 50%;
		height: 400px;
	}

	.flexbar-col-image._2 {
		width: 50%;
		height: 400px;
	}

	.projects-section-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.project-col-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		height: 300px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-right: 1px none #969797;
		text-decoration: none;
	}

	.project-col-mobile._2 {
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px solid #969797;
	}

	.project-col-mobile._1 {
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: 1px solid #969797;
		border-right-style: solid;
		-webkit-transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	.project-col-mobile._1:hover {
		background-color: transparent;
	}

	.flexbar-heading.col {
		margin-bottom: 20px;
		font-size: 2.25rem;
		text-decoration: none;
	}

	.flexbar-heading.col._1 {
		margin-right: 0rem;
		margin-left: 3rem;
		padding-right: 0rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 3rem;
	}

	.flexbar-heading.col._1 {
		margin-right: 0rem;
		margin-left: 3rem;
		padding-right: 0rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 3rem;
	}

	.flexbar-paragraph.mobile {
		display: none;
		margin-bottom: 40px;
	}

	.flexbar-paragraph._1 {
		margin-right: 3rem;
		margin-bottom: 1rem;
		margin-left: 3rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.flexbar-paragraph._1 {
		margin-right: 3rem;
		margin-bottom: 1rem;
		margin-left: 3rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-paragraph._1 {
		margin-right: 3rem;
		margin-bottom: 1rem;
		margin-left: 3rem;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.practice-group-cb.one {
		height: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.practice-group-cb.five {
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.practice-group-cb.four {
		height: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.practice-group-cb.six {
		height: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.practice-group-cb.two {
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.practice-group-cb.three {
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.hover-circle-wrapper {
		display: none;
	}

	.number-wrapper {
		width: auto;
		margin-bottom: 0.25rem;
	}

	.hero-slider {
		min-height: 90vh;
	}

	.hero-container {
		padding-right: 3rem;
		padding-bottom: 6rem;
		padding-left: 3rem;
		height: auto;
	}

	.hero__slide-nav {
		right: 3rem;
	}

	.menu-button {
		margin-right: 3rem;
		margin-left: 0px;
		padding: 0px;
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		color: #fff;
		font-size: 2rem;
		line-height: 2rem;
		font-weight: 400;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.slider-arrow {
		width: 160px;
		height: 160px;
	}

	.stat-text-wrapper {
		width: auto;
	}

	.flexbar-images {
		display: none;
	}

	.flexbar-column-wrapper {
		display: none;
	}

	.practice-groups-paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.stats-cb {
		width: auto;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border: 1px none #000;
	}

	.stats-cb:hover {
		border: 1px none #000;
	}

	.stats-cb.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.stats-cb.two:hover {
		border-style: none;
	}

	.stats-cb.four {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.stat-icon.offices {
		width: 50px;
		height: 50px;
	}

	.stat-number {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 3.25rem;
	}

	.stat-title {
		color: #335962;
		font-size: 1rem;
		text-align: center;
	}

	.search-icon {
		margin-top: 10px;
	}

	.search-icon.mobile {
		margin-top: 0px;
		margin-right: 2rem;
		margin-left: auto;
		padding-left: 0px;
	}

	.search-button-icon {
		width: 15px;
		margin-top: 0px;
		background-size: 35px;
	}

	.search-button-icon:hover {
		background-size: 35px;
	}

	.search-content-form {
		width: 100vw;
		top: 90px;
		margin: 10px 0 0 0;
		padding-right: 2rem;
		padding-left: 2rem;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
	}

	.navbar-wrapper.sticky + .search-content-form {
		top: 56px;
	}

	.search-input {
		padding-left: 0px;
	}

	.exit-button-icon {
		margin-top: 5px;
		background-size: 35px;
	}

	.exit-button-icon:hover {
		background-size: 35px;
	}

	.cb-box.one {
		margin-bottom: 0rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.col-2 {
		height: 450px;
	}

	.inner-content-col._1 {
		padding-left: 2rem;
	}

	.inner-content-col._2 {
		padding-right: 2rem;
	}

	.gray-color-block {
		top: 400px;
		height: 505px;
	}

	.practice-group-cb-image {
		width: 100%;
		height: auto;
		margin-right: 0rem;
	}

	.practice-group-cb-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-group-cb-wrapper.top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.practice-heading {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.practice-group-inner-content {
		width: 60%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.paragraph-3 {
		max-width: none;
	}

	.paragraph-3.news {
		margin-bottom: 1rem;
	}

	.practice-cb.three {
		width: 48%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: transparent;
	}

	.practice-cb.one {
		width: 48%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: transparent;
	}

	.practice-cb.two {
		width: 48%;
		margin: 0rem 0rem 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		background-color: transparent;
	}

	.practice-cb.four {
		width: 48%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: transparent;
	}

	.stat-icon-2.offices {
		width: 50px;
		height: 50px;
	}

	.stat-number-2 {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 3.25rem;
	}

	.stats-section-2 {
		width: 100%;
		margin-bottom: 2.5rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.stats-cb-2 {
		width: 100%;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: solid none;
		border-width: 1px;
		border-color: #e2e2e2 #000;
	}

	.stats-cb-2:hover {
		border-style: solid none;
		border-width: 1px;
		border-color: #e2e2e2 #000;
	}

	.stats-cb-2.two {
		margin-left: 0rem;
		padding: 0.5rem 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.stats-cb-2.four {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.stat-content-wrapper-2 {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.stat-text-wrapper-2 {
		width: 70%;
	}

	.number-wrapper-2 {
		width: 31%;
		margin-bottom: 0.25rem;
	}

	.stat-title-2 {
		font-size: 1.125rem;
	}

	.secondary-button1.flexbar-link-two {
		margin-left: 3rem;
	}

	.secondary-button1.flexbar-link {
		margin-left: 3rem;
	}

	.section-heading-2.practice-groups {
		width: auto;
		margin-right: 0px;
		margin-left: 0px;
	}

	.group-slide {
		width: 75%;
		margin-right: 0rem;
	}

	.right-arrow {
		right: 28vw;
	}

	.practice-groups-slider {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slide-nav {
		display: none;
	}

	.left-arrow {
		right: 35vw;
	}

	.practice-groups-slider-mask {
		width: 33.33%;
		height: 700px;
	}

	.heading-section {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.heading-section.practice-groups {
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.section-content-2 {
		padding: 3rem 3rem 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.section-content-2.practice-groups-section {
		padding-top: 3rem;
		padding-bottom: 0rem;
	}

	.practice-groups-paragraph-2 {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		padding-left: 2rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.primary-button {
		margin-top: 5px;
		margin-left: -27px;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.underline-image.blue {
		margin-left: 15px;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
	}

	.content-container.threequarterwidth {
		width: 100%;
	}

	.button-link {
		position: static;
		margin-top: 20px;
		font-size: 11px;
	}

	.button-link.two {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-top: 50px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		font-size: 12px;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.left-nav-wrapper {
		display: none;
	}

	.secondary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.inside-page-header-section {
		min-height: 200px;
	}

	.heading-2 {
		font-size: 38px;
	}

	.inside-page-content {
		margin-top: 260px;
		margin-right: 5%;
		margin-left: 5%;
	}

	.cookie-div {
		width: 90%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cookie-text-para {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.container-2.footer.gdrp {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.gdrp-link {
		margin-right: 1rem;
	}

	.exit-icon-div {
		right: 1rem;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.breadcrumb-link-2 {
		margin-top: 0.25rem;
	}

	.inside-page-content-2 {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-hero-section-2 {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.breadcrumbs-wrapper-2 {
		margin-top: 0.5rem;
		padding-left: 0rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.div-block-145 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.left-nav-wrapper-2 {
		display: none;
	}

	.inside-page-header-section-2 {
		position: relative;
		height: 450px;
		min-height: 200px;
		max-height: 50vh;
		background-position: 0 0, 0 0, 50%;
	}

	.heading-13 {
		font-size: 38px;
	}

	.footer-nav-list-link-2 {
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.social-media-link-block-2 {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block-2:hover {
		border-radius: 0%;
	}

	.social-media-link-block-2.facebook:hover {
		border-radius: 100px;
	}

	.social-media-link-block-2.linkedin:hover {
		border-color: #b08d57;
		border-radius: 100px;
	}

	.social-media-link-block-2.twitter:hover {
		border-radius: 100px;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.right-nav {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-page-content-left-right {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}
}

@media screen and (max-width: 767px) {
	.practice-group-cb-3 {
		opacity: 1 !important;
	}

	.slideshow-heading {
		max-width: 425px;
		font-size: 3.25rem;
		line-height: 2.75rem;
	}

	.slideshow-text {
		max-width: 400px;
		margin-top: 0.5rem;
		margin-bottom: 0px;
		font-size: 1.1rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 2rem;
		-webkit-transition-property: none;
		transition-property: none;
	}

	.nav-menu {
		margin-top: 0px;
	}

	.two-part-mobile-nav-wrapper.top {
		color: #b08d57;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.logo {
		margin-left: 2rem;
		padding-left: 0px;
	}

	.section-content {
		padding: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.section-content.practice-groups-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.section-content.flexbar-section {
		padding-bottom: 0rem;
	}

	.stats-section {
		width: 100%;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.paragraph.first-paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.grip-link {
		width: 30%;
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-column-wrap {
		width: 100%;
		min-width: 200px;
		padding-right: 0rem;
	}

	.footer-terms-container {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.footer-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.footer-wrapper {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-wrapper.top-row {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-wrapper.bottom-row {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-wrapper.top-row {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-column-terms {
		width: 100%;
		margin-right: 0rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-column-links {
		width: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-nav-list-link {
		margin-top: 0.25rem;
		margin-left: 0rem;
	}

	.footer-nav-list-link:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.footer-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin: 0rem auto 1rem 0px;
		padding-top: 0rem;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-ordinal-group: 5;
		-webkit-order: 4;
		-ms-flex-order: 4;
		order: 4;
	}

	.section-heading {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.social-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0rem;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-link-block {
		margin-right: 0.75rem;
	}

	.social-media-link-block:hover {
		border-radius: 100%;
	}

	.social-media-link-block.twitter {
		border-left-style: solid;
	}

	.social-media-link-block.linkedin {
		margin-right: 0rem;
		border-left-style: solid;
	}

	.social-media-link-block.facebook {
		border-left-style: none;
	}

	.our-firm-section {
		width: 100%;
		margin-bottom: 2rem;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 250px;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 250px;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 250px;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 250px;
	}

	.project-col-mobile {
		height: 275px;
	}

	.project-col-mobile._2 {
		width: 100%;
		height: 250px;
	}

	.project-col-mobile._1 {
		width: 100%;
		height: 250px;
	}

	.flexbar-heading {
		font-size: 2.25rem;
		line-height: 2.25rem;
	}

	.flexbar-heading.col {
		margin-bottom: 15px;
		font-size: 1.8rem;
		line-height: 2rem;
	}

	.flexbar-heading.col._1 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.flexbar-heading.col._2 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.flexbar-heading.col._1 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.flexbar-heading.col._2 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.flexbar-paragraph._1 {
		max-width: none;
		margin-bottom: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-paragraph._1 {
		max-width: none;
		margin-bottom: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-paragraph._1 {
		max-width: none;
		margin-bottom: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.practice-group-cb.one {
		width: 100%;
		height: 100%;
	}

	.practice-group-cb.five {
		width: 100%;
		height: 100%;
		margin: 0rem;
	}

	.practice-group-cb.four {
		width: 100%;
		height: 100%;
	}

	.practice-group-cb.six {
		width: 100%;
		height: 100%;
	}

	.practice-group-cb.two {
		width: 100%;
		height: 100%;
		margin: 0rem;
	}

	.practice-group-cb.three {
		width: 100%;
		height: 100%;
	}

	.number-wrapper {
		width: 42%;
	}

	.hero-slider {
		min-height: 70vh;
	}

	.menu-button {
		margin-right: 2rem;
	}

	.slider-arrow {
		left: -80px;
		width: 140px;
		height: 140px;
		padding-left: 80px;
	}

	.stat-text-wrapper {
		width: 58%;
	}

	.slideshow-heading-text-span {
		margin-left: 0.5rem;
		font-size: 1.5rem;
	}

	.stats-cb {
		width: auto;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stats-cb.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.search-icon.mobile {
		margin-top: 0px;
		margin-left: auto;
	}

	.search-button-icon {
		margin-left: auto;
		background-size: 30px;
	}

	.search-button-icon:hover {
		background-size: 30px;
	}

	.search-content-form {
		left: auto;
		right: 0%;
		bottom: auto;
		width: 100vw;
		margin-right: 0px;
		padding-right: 2rem;
		padding-left: 2rem;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
	}

	.search-input {
		padding-left: 0px;
	}

	.exit-button-icon {
		margin-left: auto;
		background-size: 30px;
	}

	.exit-button-icon:hover {
		background-size: 30px;
	}

	.cb-box.one {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding: 1rem 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.col-2 {
		width: 100%;
		height: 250px;
	}

	.col-2._2 {
		width: 100%;
		height: 250px;
	}

	.col-1 {
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.col-1._2 {
		width: 100%;
	}

	.flexbar-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.flexbar-row._2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.inner-content-col._1 {
		padding-left: 0rem;
	}

	.gray-color-block {
		top: 575px;
		height: 500px;
	}

	.practice-group-cb-image {
		width: 100%;
		height: auto;
		margin-right: 0rem;
	}

	.practice-group-cb-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-heading {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}

	.practice-group-inner-content {
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 0rem;
	}

	.paragraph-3 {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.paragraph-3.news {
		margin-bottom: 1rem;
	}

	.practice-cb {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 2rem;
	}

	.practice-cb.three {
		margin-bottom: 1.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-cb.one {
		margin-bottom: 1.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-cb.two {
		margin-right: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-cb.four {
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-image {
		height: 175px;
	}

	.cb-image._2 {
		height: 175px;
	}

	.cb-image._3 {
		height: 175px;
	}

	.cb-image._1 {
		height: 175px;
	}

	.cb-image._4 {
		height: 175px;
	}

	.cb-image._5 {
		height: 175px;
	}

	.cb-image._6 {
		height: 175px;
	}

	.stat-icon-2.offices {
		padding-right: 5px;
		padding-left: 5px;
	}

	.stats-section-2 {
		width: 100%;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.stats-cb-2 {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stats-cb-2.two {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.stat-text-wrapper-2 {
		width: 60%;
	}

	.number-wrapper-2 {
		width: 42%;
	}

	.section-heading-2 {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.section-heading-2.practice-groups {
		width: 100%;
	}

	.group-slide {
		width: 50%;
	}

	.right-arrow {
		right: 15vw;
		display: none;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.left-arrow {
		right: 25vw;
	}

	.right-arrow-icon {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.practice-groups-slider-mask {
		width: 50%;
	}

	.heading-section {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.heading-section.practice-groups {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.section-content-2 {
		padding: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.section-content-2.practice-groups-section {
		padding-top: 2.5rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		padding-left: 2rem;
		font-size: 0.85rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.primary-button {
		margin-top: 3px;
		margin-left: -27px;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-page-container.white-background {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.underline-image.blue {
		margin-left: 15px;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.button-link.two {
		margin-left: 0px;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.content-button {
		margin-right: 15px;
		margin-left: 15px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 12px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.heading-2 {
		font-size: 36px;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.inside-page-content {
		margin-top: 260px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.cookie-div {
		width: 85%;
	}

	.cookie-text-para {
		width: 85%;
	}

	.container-2 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.inside-page-content-2 {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.h5-header-link-2 {
		font-size: 1.125rem;
	}

	.inside-hero-section-2 {
		background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/20181016082423/assets/images/Desktop---1-15.svg');
		background-image: linear-gradient(180deg, transparent, transparent), url('/20181016082423/assets/images/Desktop---1-15.svg');
		background-position: 0px 0px, 0% 50%;
	}

	.h4-header-link-2 {
		font-size: 1.25rem;
	}

	.breadcrumbs-wrapper-2 {
		margin-top: 0.25rem;
		padding-left: 0rem;
		font-size: 0.85rem;
	}

	.h3-header-link-2 {
		font-size: 1.5rem;
	}

	.h2-header-link-2 {
		font-size: 1.75rem;
	}

	.unordered-list-2 {
		padding-left: 20px;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.heading-13 {
		font-size: 36px;
	}

	.footer-nav-list-link-2 {
		margin-top: 0.25rem;
		margin-left: 0rem;
	}

	.footer-nav-list-link-2:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.social-media-link-block-2 {
		margin-right: 0.75rem;
	}

	.social-media-link-block-2:hover {
		border-radius: 100%;
	}

	.social-media-link-block-2.facebook {
		border-left-style: none;
	}

	.social-media-link-block-2.linkedin {
		margin-right: 0rem;
		border-left-style: solid;
	}

	.social-media-link-block-2.twitter {
		border-left-style: solid;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.inside-page-content-left-right {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}
}

@media screen and (max-width: 479px) {
	#module-practiceareas.homepage_contentbox.slideshow .practice-group-cb.one:hover .circle-hover.white {
		display: none;
	}

	#module-practiceareas.homepage_contentbox.slideshow .group-slide {
		display: block;
	}

	.search-content-form {
		margin-top: 10px;
		top: 70px;
	}

	.practice-group-cb-2 {
		opacity: 1 !important;
	}

	.navbar-wrapper {
		margin-top: -0.5rem;
	}

	.slideshow-heading {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.slideshow-text {
		max-width: none;
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 300;
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
		padding-left: 1.5rem;
	}

	.nav-menu {
		margin-top: -5px;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.logo {
		max-width: 175px;
		margin-left: 1.5rem;
		padding-left: 0px;
	}

	.section-content {
		padding: 2rem 1.5rem;
	}

	.section-content.practice-groups-section {
		padding-bottom: 0rem;
	}

	.paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.paragraph.first-paragraph {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.footer-text {
		margin-right: 10px;
		margin-left: 10px;
		text-align: center;
		letter-spacing: 0px;
	}

	.footer-text.rights {
		text-align: center;
		letter-spacing: 0px;
	}

	.footer-text.rights {
		text-align: center;
		letter-spacing: 0px;
	}

	.grip-link {
		width: 100%;
		padding-right: 0px;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-column-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 0px;
		margin-bottom: 1rem;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-column-wrap.social-media {
		margin-bottom: 0rem;
	}

	.footer-terms-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.footer-container {
		padding: 2rem 1.5rem 1.5rem;
	}

	.footer-wrapper {
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-wrapper.top-row {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-column-terms {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-bottom: 0.25rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-column-links {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-nav-list-link {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-left: 0px;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		line-height: 1.25rem;
	}

	.footer-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin: 0rem 0px 0rem 0rem;
		padding-top: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.section-heading {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.social-wrapper {
		margin-top: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-link-block.linkedin {
		margin-right: 0rem;
	}

	.tertiary-button.flexbar-link {
		margin-left: 1.5rem;
	}

	.tertiary-button.flexbar-link-two {
		margin-left: 1.5rem;
	}

	.tertiary-button.flexbar-link-two {
		margin-left: 1.5rem;
	}

	.tertiary-button.flexbar-link {
		margin-left: 1.5rem;
	}

	.stat-content-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 400px;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 400px;
	}

	.flexbar-col-image._1 {
		width: 100%;
		height: 400px;
	}

	.flexbar-col-image._2 {
		width: 100%;
		height: 400px;
	}

	.projects-section-mobile {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.project-col-mobile {
		width: 100%;
		height: 200px;
	}

	.project-col-mobile._2 {
		width: 100%;
		height: 400px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.project-col-mobile._1 {
		width: 100%;
		height: 400px;
	}

	.flexbar-heading.col {
		margin-bottom: 16.5px;
		font-size: 1.5rem;
	}

	.flexbar-heading.col._1 {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 1.5rem;
	}

	.flexbar-heading.col._1 {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
	}

	.flexbar-heading.col._2 {
		margin-left: 1.5rem;
	}

	.flexbar-paragraph._1 {
		margin-left: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-paragraph._1 {
		margin-left: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.flexbar-paragraph._1 {
		margin-left: 1.5rem;
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.practice-group-cb.one {
		height: 100%;
	}

	.practice-group-cb.five {
		height: 100%;
	}

	.practice-group-cb.four {
		height: 100%;
	}

	.practice-group-cb.six {
		height: 100%;
	}

	.practice-group-cb.two {
		height: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.practice-group-cb.three {
		height: 100%;
	}

	.hover-circle-wrapper {
		padding: 40px;
	}

	.number-wrapper {
		width: auto;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.hero-slide-image {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), color-stop(45%, hsla(0, 0%, 100%, 0))), -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/20181016082423/assets/images/man-in-front-of-window.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), hsla(0, 0%, 100%, 0) 45%), linear-gradient(180deg, transparent, transparent), url('/20181016082423/assets/images/man-in-front-of-window.jpg');
		background-position: 0px 0px, 0px 0px, 85% 0%;
	}

	.hero-slider {
		min-height: 65vh;
	}

	.hero-container {
		padding-right: 1.5rem;
		padding-bottom: 4rem;
		padding-left: 1.5rem;
	}

	.hero__slide-nav {
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 1rem;
		height: auto;
		margin-top: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.menu-button {
		margin-right: 1.5rem;
	}

	.slider-arrow {
		left: -60px;
		top: auto;
		bottom: 140px;
		width: 120px;
		height: 120px;
		padding-left: 60px;
	}

	.stat-text-wrapper {
		width: auto;
	}

	.slideshow-heading-text-span {
		margin-left: 0rem;
	}

	.stats-cb {
		width: 100%;
		padding: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-right-style: none;
	}

	.stats-cb:hover {
		border-style: none;
	}

	.stats-cb.two {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.stats-cb.four {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.stat-icon {
		width: 40px;
		height: 40px;
	}

	.stat-icon.offices {
		width: 40px;
		height: 40px;
		margin-right: 0.25rem;
		padding-right: 3px;
		padding-left: 3px;
	}

	.stat-icon.years {
		width: 40px;
		height: 40px;
		margin-right: 0rem;
	}

	.stat-number {
		margin-right: 1rem;
		padding-right: 0.25rem;
		padding-left: 0rem;
		font-size: 2.5rem;
	}

	.stat-title {
		font-size: 1rem;
		line-height: 1rem;
		text-align: left;
	}

	.search-icon {
		margin-right: 10px;
		margin-left: 10px;
	}

	.search-icon.mobile {
		display: block;
		margin-right: 0.75rem;
		margin-left: auto;
	}

	.search-button-icon {
		margin-top: 0px;
		padding-right: 15px;
		padding-left: 15px;
		border-radius: 0px;
		background-size: 28px;
	}

	.search-button-icon:hover {
		border-radius: 20px;
		background-size: 28px;
	}

	.search-content-form {
		left: auto;
		right: 0px;
		width: 100vw;
		height: auto;
		margin-right: 0px;
		margin-left: 0%;
		padding: 0px 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
	}

	.search-input {
		padding-left: 0px;
	}

	.search-button {
		padding: 0.65rem 0rem;
		font-size: 0.8rem;
	}

	.exit-button-icon {
		margin-top: 0px;
		padding-right: 15px;
		padding-left: 15px;
		border-radius: 0px;
		background-size: 28px;
	}

	.exit-button-icon:hover {
		border-radius: 20px;
		background-size: 28px;
	}

	.cb-box.one {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inner-content-col._1 {
		padding-left: 0rem;
	}

	.inner-content-col._2 {
		padding-right: 0rem;
	}

	.gray-color-block {
		top: 700px;
		height: 650px;
	}

	.div-block-143 {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-group-cb-image {
		width: 100%;
		height: auto;
		margin-right: 0rem;
	}

	.practice-heading {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}

	.practice-group-inner-content {
		width: 100%;
		padding: 1rem 0rem;
		border-left: 1px none rgba(192, 192, 211, 0.4);
	}

	.paragraph-3 {
		font-size: 1.05rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.paragraph-3.news {
		margin-bottom: 1rem;
	}

	.practice-cb {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-cb.three {
		width: 100%;
		margin-bottom: 2rem;
	}

	.practice-cb.one {
		width: 100%;
		margin-bottom: 2rem;
	}

	.practice-cb.two {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.practice-cb.four {
		width: 100%;
		margin-bottom: 2rem;
	}

	.cb-image._1 {
		height: 125px;
	}

	.stat-icon-2 {
		width: 40px;
		height: 40px;
	}

	.stat-icon-2.years {
		width: 40px;
		height: 40px;
		margin-right: 0rem;
	}

	.stat-icon-2.offices {
		width: 40px;
		height: 40px;
		margin-right: 0.25rem;
		padding-right: 3px;
		padding-left: 3px;
	}

	.stat-number-2 {
		margin-right: 0.5rem;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
		font-size: 2.55rem;
	}

	.stats-cb-2 {
		width: 100%;
		padding: 0.5rem 0rem;
		border-right-style: none;
	}

	.stats-cb-2:hover {
		border-style: solid none;
	}

	.stat-content-wrapper-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.stat-text-wrapper-2 {
		width: 47%;
	}

	.number-wrapper-2 {
		width: 53%;
	}

	.stat-title-2 {
		font-size: 1rem;
		line-height: 1rem;
		text-align: left;
	}

	.secondary-button1.flexbar-link-two {
		margin-left: 1.5rem;
	}

	.secondary-button1.flexbar-link {
		margin-left: 1.5rem;
	}

	.section-heading-2 {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.section-heading-2.practice-groups {
		margin-right: 0rem;
	}

	.right-arrow {
		top: -75px;
		right: 10vw;
		padding-top: 0.25rem;
	}

	.left-arrow {
		top: -75px;
		right: 30vw;
		padding-top: 0.25rem;
	}

	.practice-groups-slider-mask {
		width: 100%;
		height: 300px;
	}

	.heading-section {
		padding-bottom: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.heading-section.practice-groups {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.section-content-2 {
		padding: 2rem 1.5rem;
	}

	.section-content-2.practice-groups-section {
		padding-top: 2rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
		padding-left: 1rem;
	}

	.primary-button {
		margin-left: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-page-container.white-background {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.inside-page-container.white-background {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-page-container.white-background {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.underline-image.blue {
		margin-left: -13px;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.button-styling {
		padding-left: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.secondary-button-style {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.secondary-button {
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 0px;
		font-size: 12px;
		line-height: 17px;
	}

	.inside-page-content {
		margin-top: 260px;
	}

	.cookie-div {
		width: 100%;
		min-width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cookie-text-para {
		margin-bottom: 0.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.container-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container-2.footer {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container-2.footer.gdrp {
		padding-top: 3rem;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.gdrp-link {
		margin-right: 0rem;
	}

	.gdrp-section {
		display: block;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.inside-page-content-2 {
		margin-top: 0px;
	}

	.breadcrumbs-wrapper-2 {
		margin-top: 0rem;
		padding-left: 0rem;
	}

	.button-styling-2 {
		padding-left: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-nav-list-link-2 {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-left: 0px;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		line-height: 1.25rem;
	}

	.social-media-link-block-2.linkedin {
		margin-right: 0rem;
	}

	.inside-page-content-left-right {
		margin-top: 0px;
	}

	.button-styling-3 {
		padding-left: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.button-styling-4 {
		padding-left: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.navbar-wrapper.sticky + .search-content-form {
		top: 48px;
	}
}

/****************** Webflow ***********************/
/****************************************************/
.body {
	overflow-x: hidden !important;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.navbar-wrapper.sticky {
	background-color: #c51b00;
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0px;
	margin-left: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
}

.dropdown-toggle {
	text-decoration: none;
	font-family: Heebo, sans-serif;
	font-size: 0.95rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding-right: 20px;
	padding-left: 20px;
}

.dropdown-toggle.no-link {
	cursor: default;
}

@media (max-width: 1378px) and (min-width: 992px) {
	.dropdown-toggle {
		font-size: 0.9rem;
		padding-right: 5px;
		padding-left: 5px;
	}

	.logo {
		margin-left: 1rem;
	}

	.search-icon.mobile {
		margin-right: 1rem;
	}
}

.accesible-navigation-menu li.nav-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	margin-top: -18px;
}

.accesible-navigation-menu li.nav-wrapper .container-wrapper:before {
	width: 0;
	height: 0;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	display: block;
	content: "";
	border-bottom: 13px solid rgb(68, 69, 69);
	margin-left: 23px;
}

.accesible-navigation-menu li.nav-wrapper .container-wrapper ul {
	display: none;
	padding: 0;
	background-color: rgb(68, 69, 69);
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.nav-wrapper .container-wrapper ul a {
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 400;
	text-align: left;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	padding: 0.85rem 2.25rem 0.85rem 1.25rem;
	display: inline-block;
	text-decoration: none;
}

.accesible-navigation-menu li.nav-wrapper .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-wrapper .container-wrapper ul a.w--current {
	padding-right: 2rem;
	padding-left: 1.5rem;
	border-bottom: 1px none #fff;
	border-left: 2px solid #fff;
	background-color: transparent;
	color: #fff;
	font-weight: 400;
	display: inline-block;
}

.accesible-navigation-menu li.nav-wrapper.open .container-wrapper,
.accesible-navigation-menu li.nav-wrapper:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-wrapper.open .container-wrapper ul,
.accesible-navigation-menu li.nav-wrapper:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #444545;
}

.mobile-navigation-menu {
	margin-top: 65px;
	padding-bottom: 65px;
}

.mobile-navigation-menu li a.nav-a,
.mm-navbar__title {
	text-align: left;
	padding: 20px 3rem;
	font-family: Heebo, sans-serif;
	color: #fff !important;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.mobile-navigation-menu li.mm-listitem.active,
.mobile-navigation-menu li.mm-listitem:hover {
	border-left: 4px solid #fff;
	background-color: transparent;
	color: #fff;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #fff;
}

.mm-listitem__btn {
	border-color: rgba(255, 255, 255, 0.15);
}

.mm-listitem:after,
.mm-listitem__btn,
.mm-navbar {
	border-color: transparent;
}

@media screen and (max-width: 479px) {
	.mobile-navigation-menu {
		margin-top: 59px;
		padding-bottom: 59px;
	}
}

/********************* Homepage *********************/
/*****************************************************/
.practice-groups-slider-mask {
	position: relative;
	display: block;
	z-index: 1;
	left: 0;
	right: 0;
	white-space: nowrap;
	overflow: visible;
	flex-wrap: wrap;
	height: auto;
	padding-left: 0rem;
	width: 100%;
}

.practice-groups-paragraph-2 p {
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: center;
}

.group-slide {
	width: 25%;
	height: 450px;
}

.practice-groups-slider-mask,
.practice-groups-slider {
	position: static;
}

#module-practiceareas .right-arrow,
#module-practiceareas .left-arrow {
	position: absolute;
	top: 75px;
}

#module-practiceareas .slick-dots {
	display: none !important;
}

.flexbar-paragraph p,
.flexbar-paragraph td,
.flexbar-paragraph li,
.flexbar-paragraph a,
.flexbar-paragraph span,
.flexbar-paragraph h1,
.flexbar-paragraph h2,
.flexbar-paragraph h3,
.flexbar-paragraph h4,
.flexbar-paragraph h5,
.flexbar-paragraph h6 {
	color: inherit !important;
}

.flexbar-paragraph p {
	font-size: inherit;
	line-height: inherit;
}

.paragraph p {
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}

@media screen and (max-width: 991px) {
	.practice-groups-paragraph-2 p {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.group-slide {
		width: 33.3%;
		height: 375px;
	}

	#module-practiceareas .right-arrow,
	#module-practiceareas .left-arrow {
		top: 55px;
	}
}

@media screen and (max-width: 767px) {
	.group-slide {
		width: 50%;
	}
}

@media screen and (max-width: 479px) {
	.group-slide {
		width: 100%;
		height: 300px;
	}

	#module-practiceareas .right-arrow,
	#module-practiceareas .left-arrow {
		top: 70px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slideshow-text p {
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.hero-slider .primary-button {
	background-color: #8e7043;
	margin-left: 0px;
}

.slideshow-heading {
	text-transform: uppercase;
}

.hero-slider .w-slide {
	height: 100%;
	min-height: 100vh;
	background-position: 0px 0px, 0px 0px, 50% 50%;
}

.hero-slider .w-slider-mask {
	height: auto;
}

.hero-slider .slide-content {
	position: relative;
	height: 100%;
}

.hero-slider {
	height: auto;
}

@media screen and (max-width: 991px) {
	.hero-slider .w-slide {
		min-height: 90vh;
	}

	.hero-slider .slide-content {
		display: flex;
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	.hero-slider .w-slide {
		min-height: 70vh;
	}

	.hero-slider .slide-content {
		justify-content: flex-end;
	}
}

@media screen and (max-width: 479px) {
	.hero-slider .w-slide {
		min-height: 65vh;
	}
}

/****************** Inside Page ******************/
/*************************************************/
.left-nav-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.breadcrumbs-list .breadcrumbs-list-item:last-child .breadcrumb-divider {
	display: none;
}

.left-nav-link-5.active {
	padding-top: 15px;
	padding-bottom: 15px;
	background-image: none;
	font-weight: 600;
}

.inside-page-content-left-right.right-column .right-nav,
.inside-page-content-left-right.both-columns .right-nav {
	width: 21%;
}

.bfc {
	margin-bottom: 2rem;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.bfc p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit;
	font-weight: inherit;
}

.bfc p:last-child {
	margin-bottom: 0px;
}

.bfc {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}

	.inside-page-content-left-right.right-column .right-nav,
	.inside-page-content-left-right.both-columns .right-nav {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}

	.bfc {
		font-size: 1.125rem;
	}
}

@media (max-width: 479px) {
	.bfc {
		line-height: 1.5rem;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-nav td img {
	max-width: none;
}

.right-nav .box {
	margin-bottom: 2.441em;
	margin-left: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-nav .box p,
.right-nav .box li,
.right-nav .box td,
.right-nav .box span {
	font-size: 0.875rem;
	line-height: 1.5em;
	font-family: Heebo, sans-serif;
}

.right-nav .box p {
	font-family: Heebo, sans-serif;
	font-weight: 300;
}

.right-nav .box h4,
.right-nav .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.right-nav .box a {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1em;
	text-decoration: none;
	font-weight: 400;
	display: inline-block;
}

.right-nav .box a:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #197974;
}

.right-nav .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-nav .box.documents a,
.right-nav .box.links a {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	font-family: Heebo, sans-serif;
	font-size: 1rem;
	text-decoration: none;
	color: #335962;
}

.right-nav .box.documents a:hover,
.right-nav .box.links a:hover {
	border-bottom: 0px none rgba(156, 203, 60, 0);
	color: #197974;
}

.right-nav .box .contentbox_item_image {
	width: 24px;
}

.right-nav .box.documents td:first-child {
	padding-right: 7px;
}

.right-nav .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav .select2-container .select2-choice,
.right-nav .select2-container .select2-choice:hover {
	border: 1px solid #aaa !important;
}

@media (max-width: 991px) {
	.right-nav > div,
	.right-nav .box {
		width: 100%;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
li {
	margin-bottom: 0;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	text-decoration: none;
}

.left-sidebar a,
.main-content-section a,
.cboxes a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.2rem;
	text-decoration: none;
	line-height: 1em;
}

.left-sidebar a:hover,
.main-content-section a:hover,
.cboxes a:hover {
	border-bottom: 2px solid transparent;
	color: #197974;
}

h1 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 46px;
	line-height: 45px;
	font-weight: 300;
	text-transform: none;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 38px;
	}
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 36px;
	}
}

h2 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	font-weight: 300;
}

h3 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	font-weight: 300;
}

h4 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	font-weight: 300;
}

h5 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 500;
	text-transform: uppercase;
}

h6 {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 500;
	text-transform: uppercase;
}

.left-sidebar h1 a,
.main-content-section h1 a,
.right-sidebar h1 a,
.cboxes h1 a,
h1 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: inherit;
	line-height: 68px;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
	display: inline;
}

.left-sidebar h2 a,
.main-content-section h2 a,
.right-sidebar h2 a,
.cboxes h2 a,
h2 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-align: left;
	text-decoration: none;
	display: inline;
}

@media screen and (max-width: 767px) {
	.left-sidebar h2 a,
	.main-content-section h2 a,
	.right-sidebar h2 a,
	.cboxes h2 a,
	h2 a {
		font-size: 1.75rem;
	}
}

.left-sidebar h3 a,
.main-content-section h3 a,
.right-nav-col h3 a,
.cboxes h3 a,
h3 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
	display: inline;
}

@media screen and (max-width: 767px) {
	.left-sidebar h3 a,
	.main-content-section h3 a,
	.right-nav-col h3 a,
	.cboxes h3 a,
	h3 a {
		font-size: 1.5rem;
	}
}

.left-sidebar h4 a,
.main-content-section h4 a,
.right-nav-col h4 a,
.cboxes h4 a,
h4 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
	display: inline;
}

@media screen and (max-width: 767px) {
	.left-sidebar h4 a,
	.main-content-section h4 a,
	.right-nav-col h4 a,
	.cboxes h4 a,
	h4 a {
		font-size: 1.25rem;
	}
}

.left-sidebar h5 a,
.main-content-section h5 a,
.right-nav-col h5 a,
.cboxes h5 a,
h5 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	display: inline;
}

@media screen and (max-width: 767px) {
	.left-sidebar h5 a,
	.main-content-section h5 a,
	.right-nav-col h5 a,
	.cboxes h5 a,
	h5 a {
		font-size: 1.125rem;
	}
}

.left-sidebar h6 a,
.main-content-section h6 a,
.right-nav-col h6 a,
.cboxes h6 a,
h6 a {
	margin-bottom: 1rem;
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	display: inline;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	border-bottom: 2px solid transparent;
	color: #197974;
}

p {
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	text-decoration: none;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search .inside-hero-section-2 button {
	display: inline-block;
	padding: 0.75rem 1.25rem;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #8e7043 !important;
	font-size: 0.9rem !important;
	line-height: 1rem !important;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/bg-button.jpg');
	background-repeat: no-repeat;
	background-position: left center;
	border: 0px !important;
	transition: all 200ms ease;
	cursor: pointer;
}

.primary:hover,
.inside-row a.primary:hover .inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search .inside-hero-section-2 button:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	color: #b08d57 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	padding: 0.75rem 1.25rem 0.75rem 30px;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: rgb(68, 69, 69) !important;
	font-size: 0.9rem !important;
	line-height: 1rem !important;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/bg-button.jpg');
	background-repeat: no-repeat;
	background-position: 10px center;
	border: 1px solid #b08d57 !important;
	transition: all 200ms ease;
	cursor: pointer;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: rgb(141, 105, 50) !important;
	background-image: url('/20181016082423/assets/images/bg-button-hover.jpg');
	padding-left: 50px;
	background-position: 10px center;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.65rem 1.25rem 0.65rem 30px;
	background-color: hsla(0, 0%, 94%, 0.7);
	font-family: Heebo, sans-serif;
	color: rgb(122, 122, 122) !important;
	font-size: 0.9rem !important;
	line-height: 1rem !important;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/bg-button.jpg');
	background-repeat: no-repeat;
	background-position: 10px center;
	border: 0px !important;
	transition: all 200ms ease;
	cursor: pointer;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: rgb(141, 105, 50) !important;
	background-image: url('/20181016082423/assets/images/bg-button-hover.jpg');
	padding-left: 50px;
	background-position: 10px center;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 300;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 300;
}


#message.success p {
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 300;
}

#message.error p {
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 300;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid transparent;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #335962;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #335962;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td,
td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	font-family: Heebo, sans-serif;
	font-weight: 300;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #f0f0f0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-container input[type="text"],
.inside-page-container input[type="tel"],
.inside-page-container input[type="password"],
.inside-page-container input[type="email"],
.inside-page-container select,
.inside-page-container textarea,
.inside-page-container input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
}

.inside-page-container textarea {
	resize: vertical;
}

.inside-page-container label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-container input[type="text"],
	.inside-page-container input[type="tel"],
	.inside-page-container input[type="password"],
	.inside-page-container input[type="email"],
	.inside-page-container select,
	.inside-page-container textarea,
	.inside-page-container input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #335962;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}


/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

/*********************** Modules ********************/
/*****************************************************/
/* events */
.fc-day-grid-event .fc-time {
	font-weight: normal;
}

#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	display: block;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(92, 92, 92, 0.15);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form table {
	max-width: 991px;
	margin: 0 auto;
}

#jobs_module.cms_form p {
	max-width: 991px;
	text-align: center;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}


.right-nav .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-nav .box #frmDepartments #Department {
	width: calc(100% - 55px);
}

.right-nav .box #frmDepartments button[type="submit"].primary {
	padding: 7px 15px !important;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
	margin: 0px;
}

#locations_module.entity .location-information-container {
	display: flex;
	justify-content: space-between;
	gap: 2rem;
}

#locations_module.entity .location-information-container .location-extra-fields-container,
#locations_module.entity .location-information-container .location-manager-container {
	flex-basis: 30%;
}

#locations_module.entity .location-information-container .location-manager-container {
	border-left: 2px solid #c51b00;
	padding-left: 1rem;
}

#locations_module.entity .location-information-container .location-extra-fields-container {
	flex-grow: 1;
}

#locations_module.entity .location-information-container .location-extra-fields-container .location-description {
	margin-top: 0.5rem;
}


#locations_module.entity .location-information-container .location-manager-container h2 {
	margin-top: 0;
	text-align: center;
}

#locations_module.entity .location-information-container .location-manager-container .office-managing-partner-value {
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 4em;
	display: flex;
	flex-direction: column;
	flex: 0 0 25%;
	padding-left: 0px;
	padding-right: 0px;
	margin-top: 2rem;
}

#locations_module.entity .location-information-container .location-manager-container .office-managing-partner-value > a {
	background-position: 50% 0%;
	background-size: cover;
	background-repeat: repeat;
	height: 25vw;
	max-height: 400px;
	min-height: 200px;
	max-width: 400px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

#locations_module.entity .office-managing-partner-value > a:hover {
	opacity: 0.85;
}

#locations_module.entity .office-managing-partner-value div {
	position: relative;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 0;
	font-size: 1.0625rem;
	line-height: 1.5em;
	flex-grow: 1;
	text-align: center;
}

#locations_module.entity .office-managing-partner-value div a {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 24px;
	line-height: 1.125em;
	font-weight: 400;
	border-bottom: 0px !important;
}

#locations_module.entity .office-managing-partner-value div a:hover {
	color: #197974;
}

#locations_module.entity .location-extra-fields {
	margin-bottom: 2rem;
}

#locations_module.entity .location-extra-fields .extra-field .extra-field-label a {
	font-size: inherit;
	line-height: inherit;
}

#locations_module.entity .google_map_wrapper {
	height: 350px;
}

@media screen and (max-width: 767px) {
	#locations_module.entity .location-information-container {
		flex-direction: column;
	}

	#locations_module.entity .location-information-container .location-manager-container {
		border-left: 0;
		border-top: 2px solid #c51b00;
		padding-top: 2rem;
		padding-left: 0;
	}

	#locations_module.entity .location-information-container .location-manager-container .office-managing-partner-value > a {
		height: 50vw;
	}

	#locations_module.entity .office-managing-partner-value div {
		height: 41%;
		padding-bottom: 0;
	}

	#locations_module.entity .office-managing-partner-value div a {
		line-height: 1.125em;
	}
}

@media screen and (max-width: 479px) {
	#locations_module.entity .office-managing-partner-value {
		max-width: 100%;
		flex: 0 0 100%;
		width: 100%;
	}

	#locations_module.entity .office-managing-partner-value > a {
		height: 80vw;
		min-height: 400px;
	}

	#locations_module.entity .office-managing-partner-value div {
		width: 100%;
		padding-bottom: 0;
		float: none;
	}
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 3px;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* posts */
.posts-node .posts-comments .required_information {
	color: #ee0000;
	font-style: italic;
}

.posts-collection > .node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-collection .posts-node .posts-field h2 a {
	font-size: 1.6rem;
	line-height: 1.95rem;
}

.posts-node #post-comments .post-comments-title {
	background-color: #c51b00;
	margin-top: 20px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	background-color: #fff;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.right-nav .box.posts-blocks.posts-blocks-rss-feeds a,
.right-nav .box.posts-blocks.posts-blocks-subscribes a {
	border-bottom: 0px;
}

#posts_module.form .form_item .ui-form-input input {
	width: auto;
}

#posts_module.form .ui-form-buttons {
	text-align: left;
}

.right-nav .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-nav .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-nav .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-nav .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-nav .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 0px;
}

.right-nav .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.right-nav .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 12px 10px 7px 10px !important;
	background-color: #335962 !important;
	background-image: none;
}

/* resources */
.right-nav .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */

#staffdirectory_module.cms_list {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem 1rem;
}

#staffdirectory_module.cms_list .category_header {
	width: 100%;
	font-size: 32px;
}

#staffdirectory_module.cms_list .cms_list_item {
	align-items: flex-start;
	width: calc(50% - 1rem);
}

#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 250px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	display: none;
}

@media (max-width: 1500px) {
	#staffdirectory_module.cms_list {
		flex-direction: column;
	}

	#staffdirectory_module.cms_list .cms_list_item {
		width: calc(100% - 1rem);
	}
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

@media (max-width: 767px) {
	#staffdirectory_module.cms_list .cms_list_item .info,
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* newsletter */
#newsletters_module.form .form_container {
	max-width: 991px;
	margin: 0 auto;
}

#newsletters_module.form .form_buttons {
	max-width: 991px;
}

#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

#cookie-consent-container.minimized {
	background-color: #a1866c;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* gdpr */
#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	display: block;
}

#cookie-consent-container .consent-disclaimer {
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.3rem;
	padding-left: 1rem;
	padding-right: 1rem;
	font-family: Arial, sans-serif;
	max-width: 800px;
	width: calc(100% - 480px);
}

#cookie-consent-container {
	background: #000;
	padding: 1rem 1rem;
	display: flex;
	justify-content: center;
}

#cookie-consent-container .consent-actions {
	order: 1;
	width: 480px;
}

#cookie-consent-container .consent-actions a {
	color: #bdbdbd;
	border: 0px;
	text-decoration: underline;
}

#cookie-consent-container .consent-actions a:hover {
	color: #a1866c;
}

#cookie-consent-container .consent-actions a.consent-dismiss-trigger {
	opacity: 0.65;
}

#cookie-consent-container .consent-actions a.consent-dismiss-trigger:hover {
	opacity: 1;
}

#cookie-consent-container .consent-actions a.reject-all-trigger,
#cookie-consent-container .consent-actions a.consent-all-trigger {
	text-decoration: none;
	font-family: Heebo, sans-serif;
	color: #b08d57;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	padding-left: 20px;
	margin-left: 15px;
	text-transform: uppercase;
}

.consent-form-visible .navbar {
	z-index: 0;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	flex-direction: row !important;
	align-items: center;
}

@media (max-width: 991px) {
	#cookie-consent-container {
		flex-direction: column;
	}

	#cookie-consent-container .consent-disclaimer {
		max-width: none;
		width: 95%;
	}

	#cookie-consent-container .consent-actions {
		width: 100%;
		text-align: center;
	}

	#cookie-consent-container .consent-actions a.consent-dismiss-trigger {
		position: absolute;
		top: 15px;
		right: 15px;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container .consent-disclaimer {
		font-size: 0.7rem;
	}

	#cookie-consent-container .consent-actions {
		font-size: 0.875rem;
	}

	#cookie-consent-container .consent-actions > div {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-actions a.consent-all-trigger {
		width: 200px;
		margin: 0 auto;
	}
}

/* timelines*/
.module-timelines.controller-pages-timelines.action-index .breadcrumbs-wrapper-2 {
	display: none;
}

.module-timelines.controller-pages-timelines.action-index .heading-13 {
	margin-top: 0;
	padding-bottom: 1rem;
	width: calc(100% + 80px);
	background-color: #fff;
	margin-left: -40px;
	margin-top: -10px;
	padding-left: 40px;
	margin-right: -40px;
	padding-right: 40px;
	padding-top: 10px;
}

.module-timelines.controller-pages-timelines.action-index .div-block-145,
.module-timelines.controller-pages-timelines.action-index .content-container {
	background-color: transparent;
	padding-top: 10px;
}

.module-timelines .bfc {
	background-color: #fff;
	padding: 2rem;
}

@media (max-width: 991px) {
	.module-timelines.controller-pages-timelines.action-index .heading-13 {
		width: calc(100% + 40px);
		margin-left: -20px;
		padding-left: 20px;
		margin-right: -20px;
		padding-right: 20px;
	}
}

/* attorney profile */
.attorney-profile-view .inside-page-header-section-2 {
	display: none;
}

.attorney-profile-view .div-block-145 {
	padding: 0;
}

.attorney-profile-view .inside-hero-section-2 {
	padding-right: 0;
	padding-left: 0;
	background: none;
}

.attorney-profile-view .inside-page-header-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	min-height: 100vh;
	margin: auto;
	padding-top: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #335962;
	align-items: flex-start;
	padding-bottom: 100px;
}

.attorney-profile-view .inner-content-wrapper {
	display: flex;
	width: 60%;
	height: 100%;
	max-width: 1600px;
	margin-top: 0px;
	padding-top: 12rem;
	padding-bottom: 0rem;
	padding-left: 16%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: transparent;
	z-index: 100;
}

.attorney-profile-view .h1-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 700px;
	margin-top: 0px;
	margin-bottom: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Heebo, sans-serif;
	color: #fffefe;
	font-size: 4.5rem;
	line-height: 4.5rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 1px;
	text-transform: capitalize;
}

.attorney-profile-view .hero-text {
	max-width: 450px;
	margin-bottom: 40px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.attorney-profile-view .hero-locations br:last-child {
	display: none;
}

.attorney-profile-view .hero-text a {
	color: inherit;
	border: 0px !important;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
}

.attorney-profile-view .hero-text a:hover {
	text-decoration: underline;
}

.attorney-profile-view .attorney-abstract {
	max-width: 700px;
	margin-bottom: 0px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.2rem;
	line-height: 2rem;
	font-weight: 400;
}

.attorney-profile-view .attorney-abstract p {
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.attorney-profile-view .attorney-abstract a,
.attorney-profile-view .attorney-abstract li,
.attorney-profile-view .attorney-abstract td,
.attorney-profile-view .attorney-abstract span,
.attorney-profile-view .attorney-abstract h2,
.attorney-profile-view .attorney-abstract h3,
.attorney-profile-view .attorney-abstract h4,
.attorney-profile-view .attorney-abstract h5,
.attorney-profile-view .attorney-abstract h6 {
	color: #fff !important;
}

.attorney-profile-view .attorney-abstract li p {
	margin-bottom: 0px;
}

.attorney-profile-view .list-wrap ul,
.attorney-profile-view .attorney-tabs-content ul,
.practice-area-view .practicearea-tabs-content ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.attorney-profile-view .list-wrap ul li,
.attorney-profile-view .attorney-tabs-content ul li,
.practice-area-view .practicearea-tabs-content ul li {
	margin-bottom: 10px;
	padding-left: 2rem;
	display: inline-block;
	width: 100%;
	border-left: 3px solid #cea463;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 300;
}

.attorney-profile-view .attorney-tabs-content ul li,
.practice-area-view .practicearea-tabs-content ul li {
	color: #444545;
	font-weight: 400;
	font-family: Heebo, sans-serif;
	font-size: 1.1rem;
	line-height: 1.5rem;
}

.attorney-profile-view .attorney-tabs-content ul li a,
.practice-area-view .practicearea-tabs-content ul li a {
	color: #335962;
	font-size: 1.1rem;
	line-height: 1.5rem;
}

.attorney-profile-view .attorney-tabs-content ul li a:hover,
.practice-area-view .practicearea-tabs-content ul li a:hover {
	color: #289993;
}

.attorney-profile-view .div-block-147 {
	margin-top: 1.5rem;
	padding: 0;
}

.attorney-profile-view .div-block-147 .secondary {
	color: #fff !important;
	border-color: #fff !important;
	font-size: 1.2rem !important;
	line-height: 1rem !important;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	background-image: url('/20181016082423/assets/images/bg-button-white.jpg');
}

.attorney-profile-view .div-block-147 .secondary:hover {
	color: #fff !important;
	background-image: url('/20181016082423/assets/images/bg-button-hover-white.jpg');
	padding-left: 50px;
	background-position: 10px center;
}

.attorney-profile-view .attorney-image-large.invisible {
	display: block;
	width: auto;
	padding-top: 7rem;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-self: flex-end;
	margin-left: -7rem;
}

.attorney-profile-view .attorney-image-large.invisible img {
	max-width: 611px;
	max-height: 710px;
}

.attorney-profile-view .inside-hero-section {
	padding-top: 0rem;
	padding-right: 6rem;
	padding-left: 6rem;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/20181016082423/assets/images/Desktop---1-15.svg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
	background-attachment: scroll, fixed;
	font-family: Heebo, sans-serif;
}

.attorney-profile-view .inside-page-container.white-background {
	position: relative;
	width: auto;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
}

.attorney-profile-view .content-container.threequarterwidth {
	position: relative;
	top: -48px;
	width: 100%;
	height: auto;
	margin-bottom: 1rem;
	padding-bottom: 0rem;
}

.attorney-profile-view .div-block-144 {
	position: relative;
	top: -50px;
	bottom: auto;
	z-index: 999;
	padding: 0px 40px;
	border-top: 8px solid #335962;
	background-color: #fff;
}

.attorney-profile-view .breadcrumbs-list-2,
.practice-area-view .breadcrumbs-list-2 {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	margin-top: 20px;
	margin-bottom: 3rem;
	padding-right: 40px;
	padding-left: 40px;
	background-color: #8d6932;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
}

.practice-area-view .breadcrumbs-list-2 {
	margin-top: 0;
}

.attorney-profile-view .breadcrumbs-list-item.button-item,
.practice-area-view .breadcrumbs-list-item.button-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 30px 20px 20px;
	float: left;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.practice-area-view .breadcrumbs-list-item.button-item a {
	text-decoration: none;
	border-bottom: 0;
}

.attorney-profile-view .breadcrumb-link-2.button-link.link-others,
.practice-area-view .breadcrumb-link-2.button-link.link-others {
	padding-bottom: 0px;
	border-bottom-style: none;
	color: #fff;
}

.attorney-profile-view .flexbar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	margin-top: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: transparent;
}

.attorney-profile-view .breadcrumb-link-2.button-link,
.practice-area-view .breadcrumb-link-2.button-link {
	display: block;
	margin-top: 0px;
	border-bottom: 3px none transparent;
	opacity: 1;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1.3rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
}

.attorney-profile-view .breadcrumbs-list-item.current,
.practice-area-view .breadcrumbs-list-item.current {
	padding: 30px 20px 20px;
	background-color: #fff;
}

.attorney-profile-view .breadcrumbs-list-item.current .breadcrumb-link-2.button-link,
.practice-area-view .breadcrumbs-list-item.current .breadcrumb-link-2.button-link {
	color: rgb(68, 69, 69);
}

.attorney-profile-view .flexbar-wrapper .container,
.practice-area-view .flexbar-wrapper .container {
	display: none;
	width: 100%;
	margin: 0;
	max-width: none;
}

.attorney-profile-view .flexbar-wrapper .container.current,
.practice-area-view .flexbar-wrapper .container.current {
	display: block;
}

.attorney-profile-view .row-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	justify-content: space-between;
	border-bottom: 1px none #8d6932;
	background-color: #fff;
}

.attorney-profile-view .box-col-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0;
	padding-right: 6rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.attorney-profile-view .attorney-details-container {
	margin-top: 10px;
	font-family: Heebo, sans-serif;
	font-size: 1.1rem;
	line-height: 1.7rem;
	font-weight: 300;
	width: 100%;
	flex-grow: 1;
}

.attorney-profile-view .attorney-details-container p {
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.attorney-profile-view .attorney-details-container li p {
	margin-bottom: 0px;
}

.attorney-profile-view .attorney-details-right-column-container {
	min-width: 320px;
	margin-right: 10vw;
}

.attorney-profile-view .attorney-details-right-column-box {
	display: flex;
	overflow: visible;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.attorney-profile-view .attorney-details-right-column-box.block2 {
	align-items: center;
}

.attorney-profile-view .attorney-details-right-column-image {
	aspect-ratio: 285 / 320;
	width: 100%;
	height: auto;
	padding-bottom: 0px;
	justify-content: center;
	background-color: #efefef;
}

.attorney-profile-view .attorney-details-profile-image {
	width: auto;
	max-width: 100%;
	margin: 0px auto 0;
	padding-top: 0px;
	border-style: solid;
	border-width: 1px;
	border-color: #8d6932;
	opacity: 1;
}

.attorney-profile-view .content-block-wrap {
	display: flex;
	margin-top: -60px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-left: 40px;
	flex-direction: column;
	background-color: #fff;
}

.attorney-profile-view .content-block-wrap.content-block2 {
	display: flex;
	width: 100%;
	margin: 0 0px auto;
	padding: 20px;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	background-color: #8d6932;
}

.attorney-profile-view .row-wrap {
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	flex-direction: row;
}

.attorney-profile-view .image-16 {
	width: 32px;
	height: 32px;
	margin-right: 10px;
}

.attorney-profile-view .link-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.attorney-profile-view .link-location-2 {
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.1rem;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 0px !important;
}

.attorney-profile-view .link-location-2 a {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	border: 0px !important;
}

.attorney-profile-view .link-location-2 a:hover {
	color: #e2e2e2 !important;
}

.attorney-profile-view .heading-box {
	width: 100%;
	margin-bottom: 0rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.8rem;
	line-height: 2.5rem;
	margin: 0px;
}

.attorney-profile-view .heading-19 {
	margin-top: 10px;
	margin-bottom: 20px;
	font-family: Heebo, sans-serif;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
	color: #333;
}

.attorney-profile-view .heading-19 a {
	border: 0px !important;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

.attorney-profile-view .heading-19 a:hover {
	color: #289993;
}

.attorney-profile-view .locations-container .divider:last-child {
	display: none;
}

.attorney-profile-view .heading-21 {
	margin-top: 0px;
	margin-bottom: 20px;
	color: #335962;
	font-size: 2rem;
	font-weight: 400;
}

@media screen and (max-width: 1400px) {
	.attorney-profile-view .inner-content-wrapper {
		padding-left: 10%;
	}

	.attorney-profile-view .attorney-details-right-column-container {
		margin-right: 0;
	}
}

@media screen and (max-width: 991px) {
	.attorney-profile-view .inside-page-header-section {
		position: relative;
		min-height: 100vh;
		min-height: 200px;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.attorney-profile-view .inner-content-wrapper {
		width: 50%;
		padding-top: 4rem;
		padding-bottom: 0rem;
		padding-left: 3rem;
	}

	.attorney-profile-view .h1-heading {
		font-size: 3rem;
	}

	.attorney-profile-view .hero-text {
		font-size: 1.2rem;
	}

	.attorney-profile-view .attorney-abstract {
		font-size: 1.1rem;
		line-height: 1.7rem;
	}

	.attorney-profile-view .attorney-image-large.invisible {
		padding-top: 4rem;
		padding-left: 10px;
		margin-left: 0;
	}

	.attorney-profile-view .attorney-image-large.invisible img {
		max-width: 360px;
	}

	.attorney-profile-view .inside-hero-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.attorney-profile-view .inside-page-container.white-background {
		flex-wrap: wrap;
	}

	.attorney-profile-view .content-container.threequarterwidth {
		width: 100%;
	}

	.attorney-profile-view .div-block-144 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.attorney-profile-view .breadcrumbs-list-2 {
		display: block;
		margin-right: auto;
		margin-left: 0px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		font-family: Heebo, sans-serif;
		list-style-type: disc;
	}

	.attorney-profile-view .breadcrumbs-list-item.button-item {
		margin-bottom: 0px;
		padding-bottom: 10px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link.link-others,
	.practice-area-view .breadcrumb-link-2.button-link.link-others {
		padding-bottom: 10px;
		border-bottom-color: transparent;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link,
	.practice-area-view .breadcrumb-link-2.button-link {
		border-right: 1px none #000;
		font-size: 1.2rem;
	}

	.attorney-profile-view .box-col-1 {
		padding-right: 2rem;
	}

	.attorney-profile-view .content-block-wrap.content-block2 {
		top: 0px;
		display: flex;
		margin-top: 0;
		padding-right: 10px;
		padding-left: 10px;
		align-items: center;
		flex: 0 auto;
	}

	.attorney-profile-view .row-wrap {
		width: auto;
		margin-left: 0px;
	}

	.attorney-profile-view .image-16 {
		width: 24px;
		height: 24px;
	}

	.attorney-profile-view .link-wrap {
		min-width: 70px;
	}

	.attorney-profile-view .link-location-2 {
		width: 100%;
		min-width: 70px;
		font-size: 0.9rem;
	}

	.attorney-profile-view .heading-box {
		margin-bottom: 0.5rem;
		font-size: 2.8rem;
		line-height: 2.05rem;
	}
}

@media screen and (max-width: 767px) {
	.attorney-profile-view .inside-page-header-section {
		min-height: 600px;
		min-height: auto;
		padding-bottom: 120px;
		padding-top: 130px;
	}

	.attorney-profile-view .inner-content-wrapper {
		width: 80%;
		max-width: none;
		padding-top: 2rem;
		padding-bottom: 0rem;
		padding-left: 2rem;
	}

	.attorney-profile-view .h1-heading {
		font-size: 2.6rem;
		line-height: 3.5rem;
	}

	.attorney-profile-view .hero-text {
		font-size: 1.2rem;
		line-height: 1.6rem;
	}

	.attorney-profile-view .attorney-abstract {
		margin-bottom: 0px;
		font-size: 1rem;
	}

	.attorney-profile-view .attorney-image-large.invisible {
		padding-top: 2rem;
		margin-right: 30px;
	}

	.attorney-profile-view .attorney-image-large.invisible img {
		max-width: 280px;
	}

	.attorney-profile-view .list-wrap ul li {
		font-size: inherit;
	}

	.attorney-profile-view .div-block-147 .secondary {
		font-size: 0.9rem !important;
		line-height: 1.5rem !important;
	}

	.attorney-profile-view .inside-hero-section {
		background-image: linear-gradient(180deg, transparent, transparent), url('/assets/images/Desktop---1-15.svg');
		background-position: 0px 0px, 50% 50%;
	}

	.attorney-profile-view .breadcrumbs-list-2 {
		margin-bottom: 1.5rem;
		padding-right: 20px;
	}

	.attorney-profile-view .breadcrumbs-list-item.button-item {
		padding-top: 20px;
		padding-bottom: 10px;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link.link-others,
	.practice-area-view .breadcrumb-link-2.button-link.link-others {
		font-size: 1.2rem;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link,
	.practice-area-view .breadcrumb-link-2.button-link {
		font-size: 1.2rem;
	}

	.attorney-profile-view .row-2 {
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.attorney-profile-view .box-col-1 {
		width: 100%;
	}

	.attorney-profile-view .attorney-details-right-column-container {
		width: 100%;
	}

	.attorney-profile-view .attorney-details-right-column-image {
		aspect-ratio: unset;
		text-align: center;
	}

	.attorney-profile-view .content-block-wrap.content-block2 {
		top: 0px;
		margin-top: 0;
		padding-right: 20px;
		padding-left: 20px;
	}

	.attorney-profile-view .link-location-2 {
		font-size: 1.1rem;
	}

	.attorney-profile-view .heading-box {
		font-size: 2.2rem;
		line-height: 1.9rem;
	}
}

@media screen and (max-width: 479px) {
	.attorney-profile-view .inside-page-header-section {
		min-height: 630px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-top: 150px;
	}

	.attorney-profile-view .inner-content-wrapper {
		position: static;
		width: 100%;
		padding: 0rem 1.5rem 0rem;
		height: auto;
		margin-bottom: 2rem;
	}

	.attorney-profile-view .h1-heading {
		margin-bottom: 0rem;
		font-size: 2.4rem;
	}

	.attorney-profile-view .hero-text {
		margin-bottom: 1rem;
		font-size: 0.9rem;
	}

	.attorney-profile-view .attorney-abstract {
		margin-bottom: 2rem;
		padding-right: 0px;
		font-size: 0.9rem;
		line-height: 1.5rem;
		font-weight: 400;
	}

	.attorney-profile-view .div-block-147 {
		margin-top: 1rem;
		margin-right: 100px;
		font-family: Heebo, sans-serif;
	}

	.attorney-profile-view .attorney-image-large.invisible {
		display: none;
	}

	.attorney-profile-view .div-block-147 .secondary {
		font-size: 0.7rem !important;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.attorney-profile-view .inside-page-container.white-background {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.attorney-profile-view .div-block-144 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.attorney-profile-view .breadcrumbs-list-item.button-item {
		padding: 10px 8px 0px;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link.link-others,
	.practice-area-view .breadcrumb-link-2.button-link.link-others {
		font-size: 1rem;
	}

	.attorney-profile-view .breadcrumb-link-2.button-link,
	.practice-area-view .breadcrumb-link-2.button-link {
		font-size: 1.05rem;
	}

	.attorney-profile-view .breadcrumbs-list-2,
	.practice-area-view .breadcrumbs-list-2 {
		padding-right: 8px;
		padding-left: 8px;
	}

	.attorney-profile-view .row-2 {
		padding-bottom: 0px;
		border: 1px none #000;
	}

	.attorney-profile-view .box-col-1 {
		padding-top: 0px;
		padding-right: 0rem;
	}

	.attorney-profile-view .content-block-wrap.content-block2 {
		padding-left: 20px;
	}

	.attorney-profile-view .link-location-2 {
		font-size: 1.1rem;
	}

	.attorney-profile-view .heading-box {
		font-size: 2.1rem;
		line-height: 1.85rem;
	}

	.attorney-profile-view .heading-19 {
		margin-bottom: 10px;
	}

	.attorney-profile-view .heading-21 {
		height: auto;
		font-size: 1.7rem;
	}
}

/******************* Content Boxes *******************/
/****************************************************/
/* events */
#events_module.homepage_contentbox {
	padding: 20px;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 80px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 80px);
}


/* news */
#news_module.homepage_contentbox {
	padding: 20px;
}

#news_module.homepage_contentbox .cms_title h3 a {
	font-size: 1.1rem;
	margin: 0px 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 0.9rem;
	margin: 0px 0px;
}

/* posts */
.cboxes .collection.posts-collection {
	padding: 20px;
}

.cboxes .collection.posts-collection .posts-collection > .node {
	border-top: 0px;
}

/* forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
	padding: 20px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

#locations_module .office-managing-partner-value a,
#locations_module .attorneys-value a {
	border-bottom: none;
}

#locations_module .office-managing-partner-value {
	padding-bottom: 20px;
}

#locations_module .office-managing-partner-value img,
#locations_module .attorney-info img {
	width: 150px;
	padding-bottom: 10px;
	padding-right: 10px;
}

/******************* Attorenys **************************/
/*********************************************************/
/* list */
.module-attorneys.photo {
	display: flex;
	margin-left: -10px;
	margin-right: -10px;
	flex-wrap: wrap;
	max-width: 2800px;
	margin-left: auto;
	margin-right: auto;
}

.module-attorneys.photo .attorney-listing-box,
.module-attorneys.photo .firm-managing-partner-listing-box {
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 4em;
	width: 14.285%;
	display: flex;
	flex-direction: column;
}

.module-attorneys.photo .attorney-listing-box-image {
	border-top: 5px solid #163a59;
	border-top-color: #197974;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	padding-bottom: 112%;
	height: auto;
	overflow: hidden;
	align-items: center;
	border-bottom: 0px !important;
}

.module-attorneys.photo .attorney-listing-box-image.no-image {
	background-position: 50% 50%;
}

.module-attorneys.photo .attorney-listing-box-image:hover {
	opacity: 0.85;
}

.attorney-listing-box-details {
	position: relative;
	width: 100%;
	padding-top: 1em;
	padding-bottom: 0rem;
	font-size: 1.0625rem;
	line-height: 1.5em;
	flex-grow: 1;
}

.attorney-listing-box-title {
	margin-top: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	font-size: 24px;
	line-height: 1.125em;
	font-weight: 400;
	text-transform: uppercase;
}

.attorney-listing-box-link {
	border-bottom-style: solid !important;
	border-bottom-color: transparent !important;
	color: #335962 !important;
	font-size: 24px !important;
	line-height: 30px !important;
	font-weight: 400;
	text-decoration: none;
	text-transform: capitalize;
}

.attorney-listing-box-category {
	margin-top: 0px;
	color: #444545;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}

.attorney-listing-box-contact {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	margin-bottom: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.attorney-listing-box-contact-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-bottom: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.attorney-listing-box-contact-row img {
	width: 24px;
	height: auto;
	margin-right: 20px;
}

.attorney-listing-box-contact-row-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.attorney-listing-box-contact-row-link .location {
	color: #444545;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 0px !important;
}

.attorney-listing-box-contact-row-link .location:hover {
	color: #289993;
}

.attorney-listing-box-contact-row-link .phone {
	color: #335962;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 0px !important;
	font-size: 17px;
}

.attorney-listing-box-contact-row-link .phone:hover {
	color: #289993;
}

.attorney-listing-box-contact-row-link .mail {
	color: #444545;
	font-weight: 300;
	text-decoration: none;
	border-bottom: 0px !important;
}

.attorney-listing-box-contact-row-link .mail:hover {
	color: #289993;
}

.attorney-listing-box .secondary,
.firm-managing-partner-listing-box .secondary {
	border: 0px !important;
	margin-left: -10px;
	color: #b08d57 !important;
}

.attorney-listing-box .secondary:hover,
.firm-managing-partner-listing-box .secondary:hover {
	color: rgb(68, 69, 69);
}

@media screen and (max-width: 2440px) {
	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 16.66%;
	}
}

@media screen and (max-width: 1920px) {
	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 20%;
	}
}

@media screen and (max-width: 1510px) {
	.module-attorneys.photo {
		max-width: 1220px;
	}

	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 25%;
	}
}

@media screen and (max-width: 991px) {
	#module-attorneys-top-navigation.nav-list {
		display: none;
	}

	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 33%;
	}

	.module-attorneys.photo .attorney-listing-box-details {
		font-size: 0.9375rem;
	}

	.module-attorneys.photo .attorney-listing-box-contact-row {
		height: auto;
	}

	.module-attorneys.photo .attorney-listing-box-contact-row img {
		height: 24px;
	}

	.module-attorneys.photo .attorney-listing-box-contact-row-link .location,
	.module-attorneys.photo .attorney-listing-box-contact-row-link .phone,
	.module-attorneys.photo .attorney-listing-box-contact-row-link .mail {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}
}

@media screen and (max-width: 767px) {
	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 50%;
		margin-bottom: 2rem;
	}

	.module-attorneys.photo .attorney-listing-box-details {
		height: 41%;
		padding-bottom: 0rem;
	}

	.module-attorneys.photo .attorney-listing-box-title {
		line-height: 1.125em;
	}
}

@media screen and (max-width: 479px) {
	.module-attorneys.photo {
		flex-direction: column;
	}

	.module-attorneys.photo .attorney-listing-box,
	.module-attorneys.photo .firm-managing-partner-listing-box {
		width: 100%;
	}

	.module-attorneys.photo .attorney-listing-box-details {
		width: 100%;
		padding-bottom: 0rem;
		float: none;
	}

	.module-attorneys.photo .attorney-listing-box-contact {
		margin-bottom: 5px;
	}
}

/* search */
.attorney-search {
	position: relative;
	top: auto;
	z-index: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-bottom: 30px;
	padding-top: 33px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	opacity: 1;
	max-width: 1220px;
	padding-left: 30px;
	padding-right: 30px;
	margin-left: auto;
	margin-right: auto;
}

.attorney-search-inner {
	display: block;
	width: 100%;
	padding: 1rem 2rem;
	background-color: rgba(0, 0, 0, 0.1);
	color: #f8f9fa;
}

.attorney-search-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 0.75rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 1px solid #b08d57;
}

.attorney-search-heading {
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #444545;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.attorney-search-view-all {
	padding-right: 0.75rem;
	background-image: url('/assets/images/arrow-medium.svg');
	background-position: 100% 47%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Heebo, sans-serif;
	color: #444545 !important;
	font-size: 0.85rem !important;
	line-height: 0.85rem !important;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 0 !important;
}

.attorney-search-view-all:hover {
	color: #b08d57;
}

.attorney-search-form-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.attorney-search-divider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90px;
	padding-top: 5px;
	padding-bottom: 5px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #b08d57;
}

.attorney-search-vert-line {
	width: 1px;
	height: 40%;
	border-left: 1px solid #b08d57;
	color: #b08d57;
}

.attorney-search-or {
	color: #444545;
	font-size: 0.9rem;
	line-height: 1.1rem;
}

.attorney-search-alpha {
	width: 230px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.attorney-search-alpha-title {
	margin-bottom: 1rem;
	color: #444545;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 300;
}

.attorney-search-alpha-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.attorney-letter-search {
	display: inline-block;
	width: 17px;
	margin-right: 0.5rem;
	margin-bottom: 0.125rem;
	padding: 0.25rem 0.125rem;
	font-family: Montserrat, sans-serif;
	color: #444545;
	font-size: 0.9rem;
	line-height: 1.1rem;
	font-weight: 300;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.attorney-letter-search.active {
	color: #444545;
	cursor: pointer;
}

.attorney-search-section-title {
	margin-bottom: 1rem;
	color: #444545;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 300;
}

.attorney-search-label-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: -20px;
	padding-bottom: 0px;
}

.attorney-search-label-name {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 34%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	color: #444545;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-top: 10px;
}

.attorney-search-label-name.first {
	width: 34%;
}

.attorney-search-label-name.last {
	width: 32%;
}

.attorney-search-sublabel-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.attorney-search-sublabel-name {
	width: 34%;
	color: #444545;
	font-size: 14px;
	font-weight: 300;
	text-transform: lowercase;
}

.attorney-search-sublabel-name.first {
	width: 34%;
}

.attorney-search-sublabel-name.last {
	width: 32%;
}

.attorney-search-form-block {
	margin: 0px 0px 30px;
}

.attorney-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.attorney-search-form-field {
	width: 32% !important;
	margin-bottom: 25px;
}

.attorney-search-form-select {
	width: 32% !important;
	margin-bottom: 0px;
	padding-right: 24px;
	padding-left: 8px;
	border-radius: 4px;
	color: rgba(68, 69, 69, 0.6);
	margin-bottom: 25px;
}

.attorney-search-button {
	width: 100%;
	padding-left: 0px;
	padding-right: 0px;
	background-position: calc(50% - 50px) center;
}

.attorney-search-button:hover {
	padding-left: 0px;
	background-position: calc(50% - 50px) center;
}

.attorney-search-form-column {
	flex-grow: 1;
}

@media screen and (max-width: 991px) {
	.attorney-search {
		padding: 10px 10px 0px 10px;
	}

	.attorney-search-inner {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.attorney-search-form-wrapper {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.attorney-search-form-column {
		width: 60%;
	}

	.attorney-search-divider {
		width: 10%;
	}

	.attorney-search-alpha {
		width: 30%;
	}

	.attorney-search-alpha-text {
		margin-right: -1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.attorney-letter-search {
		margin-right: 1rem;
		margin-bottom: 0.75rem;
	}

	.attorney-search-section-title {
		line-height: 1.2rem;
	}
}

@media screen and (max-width: 767px) {
	.attorney-search-form-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.attorney-search-form-column {
		width: 100%;
	}

	.attorney-search-divider {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.attorney-search-vert-line {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		height: 2px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px solid #b08d57;
	}

	.attorney-search-vert-line.horizontal-line {
		width: 50%;
		border-top: 1px solid #b08d57;
	}

	.attorney-search-or {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.attorney-search-alpha {
		width: 100%;
		padding-bottom: 0.75rem;
	}

	.attorney-search-alpha-title {
		line-height: 1.2rem;
	}

	.attorney-search-alpha-text {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
}

@media screen and (max-width: 479px) {
	.attorney-search {
		padding-top: 20px;
	}

	.attorney-search-inner {
		width: 100%;
	}

	.attorney-search-view-all {
		font-size: 0.75rem;
	}

	.attorney-search-form-wrapper {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.attorney-search-form-column {
		width: 100%;
	}

	.attorney-search-divider {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.attorney-search-vert-line {
		width: 60%;
		height: 1px;
		border-top: 1px solid #b08d57;
	}

	.attorney-search-vert-line.horizontal-line {
		border-top-color: #b08d57;
	}

	.attorney-search-alpha {
		width: 100%;
		padding-bottom: 0rem;
	}

	.attorney-search-alpha-title {
		margin-top: 10px;
		margin-bottom: 0rem;
	}

	.attorney-search-alpha-text {
		padding-top: 10px;
	}

	.attorney-search-section-title {
		margin-top: 10px;
		margin-bottom: 0rem;
	}

	.attorney-search-label-name {
		font-size: 16px;
	}

	.attorney-search-label-name.first {
		width: 34%;
		font-size: 16px;
	}

	.attorney-search-form-field,
	.attorney-search-form-select {
		font-size: 14px;
	}
}

/* Practice Areas */
.practiceareas-image {
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.practiceareas-heading {
	margin-bottom: 1.5rem;
	margin-top: 0;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(25, 121, 116, 0.2);
	font-size: 2rem;
	line-height: 44px;
	font-weight: 400;
}

h3.practiceareas-heading-small {
	margin-top: 0.75rem;
	margin-bottom: 2rem;
}

h3.practiceareas-heading-small a {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-weight: 300;
	font-size: 24px;
	line-height: 36px;
}

.practiceareas-column-box h3 {
	text-align: center;
}

.practiceareas-chair-image {
	display: flex;
	justify-content: center;
	text-align: center;
}

.practiceareas-chair-image img {
	max-width: 70%;
}

.practiceareas-chair-image a {
	border-bottom: 0;
}

.practiceareas-chair-image a:hover {
	border-bottom: 0;
}

.practiceareas-chair-image a.multiple-chairs {
	background-size: cover;
	background-position: 50% 50%;
	width: 100%;
	aspect-ratio: 10 / 12;
	display: block;
	max-width: 300px;
}

.practiceareas-overview,
.practiceareas-overview p {
	font-family: Heebo, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.practiceareas-column-box ul {
	padding-left: 20px;
}

.practiceareas-column-box ul a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 1rem;
	line-height: 1.5rem;
	border-bottom: 2px solid transparent;
	font-weight: 400;
}

.practiceareas-column-box ul a:hover {
	color: #197974;
}

.practiceareas-column-box.attorneys ul {
	columns: 2;
}

.practiceareas-column-box.attorneys ul li {
	padding-right: 8px;
}

.practiceareas-main-column.full-width {
	width: 100%;
}

@media screen and (max-width: 1300px) {
	.practiceareas-column-box.attorneys ul {
		columns: 1;
	}
}

@media screen and (max-width: 991px) {
	.practiceareas-image {
		height: 250px;
	}
}

@media screen and (max-width: 767px) {
	.practiceareas-image {
		height: 200px;
	}

	.practiceareas-column-box ul {
		padding-left: 40px;
	}

	.practiceareas-column-box.attorneys ul {
		columns: 2;
	}
}

@media screen and (max-width: 479px) {
	.practiceareas-image {
		height: 150px;
	}

	.practiceareas-column-box.attorneys ul {
		columns: 1;
	}
}

/* practice area listinsg */
#module-practiceareas.thumbnail-view {
	overflow: hidden;
	background-color: #8d6932;
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	column-gap: 8px;
	row-gap: 8px;
}

#module-practiceareas.thumbnail-view .practice-area-item {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.4);
	position: relative;
	overflow: hidden;
	height: 380px;
	padding: 2rem;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	text-decoration: none;
	border-bottom: 0px !important;
}

#module-practiceareas.thumbnail-view .practice-area-heading {
	position: relative;
	z-index: 10;
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.75rem;
	font-weight: 300;
	text-align: center;
}

#module-practiceareas.thumbnail-view .practice-area-text-wrap {
	display: none;
	opacity: 0;
	z-index: 10;
}

#module-practiceareas.thumbnail-view .practice-area-text {
	position: relative;
	display: block;
	height: 100%;
	max-width: 550px;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 300;
	text-align: center;
	text-decoration: none;
}

#module-practiceareas.thumbnail-view .practice-area-item:hover .practice-area-text-wrap {
	display: block;
	opacity: 1;
}

#module-practiceareas.thumbnail-view .practice-area-item .circle-hover.white {
	transition: all 2s ease;
}

#module-practiceareas.thumbnail-view .practice-area-item:hover .circle-hover.white {
	width: 70vw !important;
	height: 70vw !important;
	transform: translate3d(3vw, -3vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
	transform-style: preserve-3d !important;
}

@media screen and (max-width: 1300px) {
	#module-practiceareas.thumbnail-view {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 991px) {
	#module-practiceareas.thumbnail-view .practice-area-text {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	#module-practiceareas.thumbnail-view {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 767px) {
	#module-practiceareas.thumbnail-view .practice-area-item {
		height: 300px;
	}
}

@media screen and (max-width: 479px) {
	#module-practiceareas.thumbnail-view {
		grid-template-columns: 1fr;
	}

	#module-practiceareas.thumbnail-view .practice-area-item .circle-hover.white {
		display: none;
	}
}


/* location list */
#locations_module.list h2 {
	margin-top: 0;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 46px;
	line-height: 45px;
	font-weight: 300;
	text-transform: none;
}

#locations_module.list .locations-list-items {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
}

#locations_module.list .locations-list-items .list-item {
	display: flex;
	overflow: hidden;
	width: 32%;
	margin: 20px 0px 40px;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-repeat: no-repeat;
	box-shadow: 1px 1px 4px 0 rgb(51 51 51 / 5%);
	transition: transform 200ms ease, opacity 200ms ease-in;
	opacity: 0;
	margin-right: 2%;
}

#locations_module.list .locations-list-items .list-item.second {
	transition: transform 200ms ease, opacity 800ms ease-in;
}

#locations_module.list .locations-list-items .list-item.third {
	transition: transform 200ms ease, opacity 1200ms ease-in;
	margin-right: 0;
}

#locations_module.list .locations-list-items .list-item:hover {
	transform: scale(1.02);
}

#locations_module.list .locations-list-items .list-item.active {
	opacity: 1;
}

#locations_module.list .locations-list-items .list-item .list-item-image {
	height: 13.4vw;
	min-height: 220px;
	background-color: #ccc;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom: 0px;
}

#locations_module.list .locations-list-items .list-item .list-item-content {
	display: block;
	min-height: 300px;
	padding: 15px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	border-style: solid;
	border-width: 1px;
	border-color: #fffefe;
	background-color: #fffefe;
	flex-grow: 1;
}

#locations_module.list .locations-list-items .list-item .list-item-title {
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2rem;
	line-height: 2.6rem;
	font-weight: 300;
}

#locations_module.list .locations-list-items .list-item .list-item-title a,
#locations_module.list .locations-list-items .list-item .list-item-title a:hover {
	border-bottom: 0px;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-family: inherit;
}

#locations_module.list .locations-list-items .list-item .list-item-address {
	display: block;
	margin-bottom: 0;
	padding: 5px 9px 5px 0px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1.05rem;
	line-height: 1.6rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: 1px;
	text-transform: capitalize;
}

#locations_module.list .locations-list-items .list-item .list-item-website {
	padding: 5px 9px 5px 0px;
}

#locations_module.list .locations-list-items .list-item .list-item-website div {
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-weight: 300;
}

#locations_module.list .locations-list-items .list-item .list-item-phone {
	display: block;
	margin-bottom: 0;
	padding: 5px 9px 5px 0px;
	flex-direction: row;
	align-items: center;
	border-radius: 8px;
	background-color: transparent;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1.05rem;
	line-height: 1.6rem;
	font-weight: 300;
	text-align: left;
	letter-spacing: 1px;
	text-transform: capitalize;
}

#locations_module.list .locations-list-items .list-item .secondary {
	border: 0px !important;
	color: #b08d57 !important;
}

@media screen and (max-width: 991px) {
	#locations_module.list .locations-list-items .list-item {
		width: 32%;
	}

	#locations_module.list .locations-list-items .list-item .list-item-image {
		height: 21.5vw;
		min-height: 130px;
	}
}

@media screen and (max-width: 767px) {
	#locations_module.list .locations-list-items .list-item {
		width: 32%;
	}

	#locations_module.list .locations-list-items .list-item .list-item-title {
		margin-top: 10px;
		font-size: 1.5rem;
		line-height: 2rem;
	}

	#locations_module.list .locations-list-items .list-item .list-item-address {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	#locations_module.list .locations-list-items .list-item .list-item-phone {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	#locations_module.list .locations-list-items .list-item .list-item-image {
		height: 25vw;
		min-height: 80px;
	}
}

@media screen and (max-width: 479px) {
	#locations_module.list .locations-list-items {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	#locations_module.list .locations-list-items .list-item {
		width: 100%;
		min-height: auto;
		margin-bottom: 20px;
	}

	#locations_module.list .locations-list-items .list-item .list-item-content {
		height: auto;
		min-height: 250px;
	}

	#locations_module.list .locations-list-items .list-item.second {
		transition: transform 200ms ease, opacity 200ms ease-in;
	}

	#locations_module.list .locations-list-items .list-item.third {
		transition: transform 200ms ease, opacity 200ms ease-in;
		margin-right: 0;
	}

	#locations_module.list .locations-list-items .list-item .list-item-image {
		height: 55vw;
		min-height: 238px;
	}
}


/* careers homepage */
.careers-homepage .inside-page-content {
	position: relative;
	z-index: 0;
	display: block;
	margin-top: 0px;
	margin-right: 0%;
	margin-left: 0%;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: none;
}

.careers-homepage .inside-page-header-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 450px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: #b08d57;
	background-position: 0px 0px, 0px 0px, 100% 25%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.careers-homepage .inside-hero-section {
	position: relative;
	margin-top: 0rem;
	padding-top: 0%;
	padding-right: 6rem;
	padding-left: 6rem;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.05)), to(rgba(0, 0, 0, 0.05))), url('/assets/images/Desktop---1-15.svg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('/assets/images/Desktop---1-15.svg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, auto;
	background-repeat: repeat, repeat;
	background-attachment: scroll, scroll;
	font-family: Heebo, sans-serif;
}

.careers-homepage .inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.careers-homepage .inside-page-container.white-background {
	position: relative;
	top: auto;
	width: auto;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: transparent;
}

.careers-homepage .content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.careers-homepage .content-container.threequarterwidth {
	position: relative;
	top: -48px;
	width: 100%;
	height: auto;
	margin-bottom: 0rem;
	padding-bottom: 0rem;
	background-color: transparent;
}

.careers-homepage .div-block-144 {
	position: relative;
	top: -30px;
	bottom: 0%;
	z-index: 99;
	margin-top: 0rem;
	padding: 10px 40px 0px;
	border-top: 8px solid #335962;
	background-color: #fff;
}

.careers-homepage .breadcrumbs-wrapper {
	display: block;
	max-width: 1500px;
	margin-bottom: 30px;
	padding-left: 0rem;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.careers-homepage .heading-13 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #335962;
	font-size: 46px;
	line-height: 45px;
	font-weight: 300;
	text-transform: none;
}

.careers-homepage .heading-13 a,
.careers-homepage .heading-13 a:hover {
	border-bottom: 1px solid transparent;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.careers-homepage .intro-paragraph {
	margin-bottom: 2rem;
	font-family: Heebo, sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 300;
}

.careers-homepage .intro-paragraph p {
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-style: inherit;
	font-size: inherit;
}

.careers-homepage .intro-paragraph p:last-child {
	margin-bottom: 0px;
}

.careers-homepage .content-boxes {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.careers-homepage .box-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 25%;
	height: 500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.careers-homepage .box-caption-1 {
	display: block;
	overflow: hidden;
	width: 100%;
	height: 93px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, #335962), to(transparent));
	background-image: linear-gradient(360deg, #335962 10%, transparent);
}

.careers-homepage .box-caption-1.box-large {
	display: block;
	height: 150px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.careers-homepage .heading-24 {
	margin-bottom: 20px;
	margin-left: 20px;
	padding-left: 0px;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 2.5rem;
	line-height: 2.8rem;
	font-weight: 300;
	text-align: left;
	white-space: break-spaces;
}

.careers-homepage .bold-text {
	line-height: 2.8rem;
	font-weight: 300;
}

.careers-homepage .box-paragraph-1 {
	display: block;
	margin-top: 0px;
	margin-bottom: 40px;
	padding-right: 20px;
	padding-left: 20px;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
}

.careers-homepage .primary-button-2.inside-page {
	display: block;
	width: 210px;
	margin-top: 0px;
	margin-right: 2rem;
	margin-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.careers-homepage .primary-button-2.inside-page.white-button {
	position: relative;
	z-index: auto;
}

.careers-homepage .secondary-button2.about-section.white-button {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-bottom: 1px solid transparent;
}

.careers-homepage .link-horiz-line-3 {
	width: 15px;
	height: 2px;
	margin-right: 0.5rem;
	background-color: #b08d57;
}

.careers-homepage .link-horiz-line-3.white-line {
	background-color: #fff;
}

.careers-homepage .text-link-5 {
	font-family: Heebo, sans-serif;
	color: #b08d57;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border-bottom: 1px solid transparent;
}

.careers-homepage .text-link-5.white-link {
	color: #fff;
}

.careers-homepage .box-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 25%;
	height: 500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.careers-homepage .box-caption-3 {
	width: 100%;
	height: 93px;
	padding-top: 0px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, #335962), to(transparent));
	background-image: linear-gradient(360deg, #335962 10%, transparent);
}

.careers-homepage .bold-text-2 {
	line-height: 2.8rem;
	font-weight: 300;
}

.careers-homepage .box-paragraph-3 {
	display: block;
	margin-bottom: 40px;
	padding-right: 20px;
	padding-left: 20px;
	opacity: 0;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
}

.careers-homepage .box-paragraph-3 p,
.careers-homepage .box-paragraph-3 a,
.careers-homepage .box-paragraph-3 a:hover,
.careers-homepage .box-paragraph-3 li,
.careers-homepage .box-paragraph-3 td,
.careers-homepage .box-paragraph-3 span,
.careers-homepage .box-paragraph-3 h1,
.careers-homepage .box-paragraph-3 h2,
.careers-homepage .box-paragraph-3 h3,
.careers-homepage .box-paragraph-3 h4,
.careers-homepage .box-paragraph-3 h5,
.careers-homepage .box-paragraph-3 h6 {
	color: #fff;
}

.careers-homepage .box-paragraph-1 p,
.careers-homepage .box-paragraph-1 a,
.careers-homepage .box-paragraph-1 a:hover,
.careers-homepage .box-paragraph-1 li,
.careers-homepage .box-paragraph-1 td,
.careers-homepage .box-paragraph-1 span,
.careers-homepage .box-paragraph-1 h1,
.careers-homepage .box-paragraph-1 h2,
.careers-homepage .box-paragraph-1 h3,
.careers-homepage .box-paragraph-1 h4,
.careers-homepage .box-paragraph-1 h5,
.careers-homepage .box-paragraph-1 h6 {
	color: #fff;
}

.careers-homepage .box-paragraph-3 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.careers-homepage .box-paragraph-1 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.careers-homepage .box-paragraph-3 p:last-child {
	margin-bottom: 0px;
}

.careers-homepage .box-paragraph-1 p:last-child {
	margin-bottom: 0px;
}


.careers-homepage .box-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 25%;
	height: 500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.careers-homepage .box-caption-3 {
	width: 100%;
	height: 93px;
	padding-top: 0px;
	background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, #335962), to(transparent));
	background-image: linear-gradient(360deg, #335962 10%, transparent);
}

.careers-homepage .bold-text-3 {
	font-size: 2.5rem;
	font-weight: 300;
}

.careers-homepage .box-paragraph-3 {
	display: block;
	margin-bottom: 40px;
	padding-right: 20px;
	padding-left: 20px;
	opacity: 0;
	font-family: Heebo, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
}

.careers-homepage .box-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 25%;
	height: 500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.careers-homepage .box-caption-3.box-large {
	display: block;
	overflow: hidden;
	height: 150px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.careers-homepage .box-caption-3.box-large.box-left-bottom {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.careers-homepage .home-content-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.careers-homepage .home-content-section.stats {
	height: auto;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.careers-homepage .home-content-section.stats {
	z-index: 2;
	height: auto;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.careers-homepage .section-content-3 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1450px;
	padding: 4rem 6rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.careers-homepage .section-content-3.section2 {
	padding-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.careers-homepage .our-firm-section-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 2rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.careers-homepage .our-firm-section-2.our-firm-2-section {
	margin-bottom: 0rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.careers-homepage .floating-dot {
	position: absolute;
	left: -5%;
	top: 2rem;
	right: auto;
	bottom: 0%;
	display: none;
	width: 8px;
	height: 8px;
	border-radius: 20px;
	background-color: #c32026;
}

.careers-homepage .section-heading-4 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 3rem;
	line-height: 2.75rem;
	font-weight: 300;
	letter-spacing: 0px;
	text-transform: none;
}

.careers-homepage .section-heading-4 a,
.careers-homepage .section-heading-4 a:hover {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	border-bottom: 1px solid transparent;
}


.careers-homepage .paragraph-18 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-bottom: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-family: Heebo, sans-serif;
	color: #444545;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 300;
	text-align: left;
	text-decoration: none;
}

.careers-homepage .paragraph-18.paragraph2 {
	margin-bottom: 1rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.careers-homepage .paragraph-18.paragraph2 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.careers-homepage .paragraph-18.paragraph2 p:last-child {
	margin-bottom: 0px;
}

.careers-homepage .div-block-25 {
	position: relative;
	z-index: 100;
	background-color: #fff;
}

.careers-homepage .scattered-images {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	padding: 60px 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.careers-homepage .link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48%;
	height: auto;
	margin-bottom: 140px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
	color: #333;
	text-decoration: none;
}

.careers-homepage .div-block-16 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: auto;
	height: 460px;
	margin-right: 10px;
	margin-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 0px;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	-webkit-transition: background-color 300ms ease;
	transition: background-color 300ms ease;
}

.careers-homepage .image-23 {
	display: none;
	overflow: hidden;
	width: auto;
	height: 150%;
	max-width: none;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.careers-homepage .heading-25 {
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 0px;
	padding-left: 0px;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.2rem;
	font-weight: 300;
	text-decoration: none;
}

.careers-homepage .bold-text-5 {
	font-weight: 300;
}

.careers-homepage .link-block-copy {
	width: 48%;
	height: auto;
	margin-top: 140px;
	background-color: transparent;
	color: #333;
	text-decoration: none;
}

.careers-homepage .div-block-17 {
	overflow: hidden;
	width: auto;
	height: 460px;
	margin-right: 10px;
	margin-left: 10px;
	border-radius: 0px;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.careers-homepage .image-24 {
	display: none;
	height: 150%;
	max-width: none;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.careers-homepage .scattered-images-second-row {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -245px;
	padding: 100px 0px 60px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.careers-homepage .div-block-20 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 400px;
	margin-right: 10px;
	margin-left: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 0px;
	background-position: 0px 0px, 50% 25%;
	background-size: auto, cover;
}

.careers-homepage .div-block-20.block-first {
	margin-left: 0px;
}

.careers-homepage .div-block-20.block-first.bootom-left {
	height: 460px;
	background-position: 0px 0px, 50% 50%;
	background-repeat: repeat, repeat;
}

.careers-homepage .image-5 {
	display: none;
	width: 150%;
	height: auto;
	max-width: none;
	margin-top: -118px;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.careers-homepage .heading-25.heading-first {
	margin-left: 10px;
}

.careers-homepage .div-block-20.botttom-block {
	height: 460px;
	background-position: 0px 0px, 50% 50%;
}

.careers-homepage .image-6 {
	display: none;
	width: 130%;
	height: auto;
	max-width: none;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.careers-homepage .home-section-2 {
	position: relative;
	z-index: 9;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #fff;
}

.careers-homepage .div-block-151 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 485px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.careers-homepage .image-wrapper {
	width: 40%;
	margin-bottom: 2rem;
	margin-left: 0rem;
}

.careers-homepage .image-22 {
	width: 100%;
	height: 485px;
	background-position: 50% 40%;
	background-size: cover;
	background-repeat: no-repeat;
}

.careers-homepage .testimonial-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	min-height: 485px;
	margin-bottom: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: rgba(51, 89, 98, 0.1);
}

.careers-homepage .testimonial-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0rem 6rem 2.5rem 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 3px solid #fff;
	background-color: transparent;
	text-align: center;
	cursor: pointer;
	-o-object-fit: fill;
	object-fit: fill;
}

.careers-homepage .heading-26 {
	margin-top: 20px;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.2rem;
	line-height: 3rem;
	font-weight: 300;
	margin-bottom: 0px;
	text-align: left;
}

.careers-homepage .heading-26 a,
.careers-homepage .heading-26 a:hover {
	border-bottom: 1px solid transparent;
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none;
	color: inherit;
}

.careers-homepage .quote-block {
	width: 50px;
	height: 50px;
	margin-bottom: 0px;
	background-image: url('/assets/images/gold-brown-quote-01.svg');
	background-position: 0% 0%;
	background-size: 35px;
	background-repeat: no-repeat;
}

.careers-homepage blockquote {
	margin: 0 0 10px 0;
	padding: 10px 20px;
	border-left: 5px solid #e2e2e2;
	font-size: 18px;
	line-height: 22px;
}

.careers-homepage .block-quote-3 {
	margin-bottom: 1rem;
	padding: 0px;
	border-left-style: none;
	border-left-color: #3eedb6;
	font-family: Heebo, sans-serif;
	color: #1d1e50;
	font-size: 1.2rem;
	line-height: 2rem;
	font-weight: 300;
	text-align: left;
}

.careers-homepage .block-quote-3 p {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.careers-homepage .block-quote-3 h2 {
	margin: 0px;
	font-family: Heebo, sans-serif;
	color: #335962;
	font-size: 2.2rem;
	line-height: 3rem;
	font-weight: 300;
	text-align: left;
}

.careers-homepage .bold-text-4 {
	font-size: 2.5rem;
	line-height: 2.8rem;
	font-weight: 300;
}

.careers-homepage .bold-text-7 {
	font-weight: 300;
}

.careers-homepage .bold-text-8 {
	font-weight: 300;
}

.careers-homepage .block-quote-3 p:last-child {
	margin-bottom: 0px;
}

@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f5f"] {
		height: 150px;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f62"] {
		opacity: 0;
		display: none;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f75"] {
		height: 93px;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f78"] {
		opacity: 0;
		display: none;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f6d"] {
		opacity: 0;
		display: none;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="45effdfe-374c-b45d-11c7-4df1e8d48f83"] {
		opacity: 0;
		display: none;
	}

	.careers-homepage .box-1:hover .box-caption-1.box-large {
		transition: all 500ms ease;
	}

	.careers-homepage .box-1:hover .box-caption-1.box-large {
		height: 100% !important;
	}

	.careers-homepage .box-1:hover .box-caption-1.box-large .box-paragraph-1 {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-1:hover .primary-button-2.inside-page.white-button {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-2:hover .box-caption-3 {
		transition: all 500ms ease;
	}

	.careers-homepage .box-2:hover .box-caption-3 {
		height: 100% !important;
	}

	.careers-homepage .box-2:hover .box-caption-3 .box-paragraph-3 {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-2:hover .primary-button-2.inside-page.white-button {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-3:hover .box-caption-3 {
		transition: all 500ms ease;
	}

	.careers-homepage .box-3:hover .box-caption-3 {
		height: 100% !important;
	}

	.careers-homepage .box-3:hover .box-caption-3 .box-paragraph-3 {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-3:hover .primary-button-2.inside-page.white-button {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-4:hover .box-caption-3 {
		transition: all 500ms ease;
	}

	.careers-homepage .box-4:hover .box-caption-3 {
		height: 100% !important;
	}

	.careers-homepage .box-4:hover .box-caption-3 .box-paragraph-3 {
		display: block !important;
		opacity: 1 !important;
	}

	.careers-homepage .box-4:hover .primary-button-2.inside-page.white-button {
		display: block !important;
		opacity: 1 !important;
	}
}

@media screen and (max-width: 991px) {
	.careers-homepage .inside-page-content {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.careers-homepage .inside-page-header-section {
		position: relative;
		height: 500px;
		min-height: 200px;
		padding-top: 100px;
		background-position: 0px 0px, 0px 0px, 50% 50%;
	}

	.careers-homepage .inside-hero-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.careers-homepage .inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.careers-homepage .content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.careers-homepage .div-block-144 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.careers-homepage .breadcrumbs-wrapper {
		margin-top: 0.5rem;
		padding-left: 0rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.careers-homepage .heading-13 {
		font-size: 38px;
	}

	.careers-homepage .content-boxes {
		z-index: auto;
		height: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		align-items: initial;
	}

	.careers-homepage .box-1 {
		width: 50%;
		height: auto;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.careers-homepage .box-caption-1 {
		position: relative;
		z-index: 99;
		overflow: visible;
		height: 320px;
		padding-top: 0px;
		padding-right: 40px;
		padding-left: 40px;
		background-image: -webkit-gradient(linear, left bottom, left top, color-stop(10%, #335962), to(transparent));
		background-image: linear-gradient(360deg, #335962 10%, transparent);
	}

	.careers-homepage .box-caption-1.box-large {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.careers-homepage .heading-24 {
		margin-top: 20px;
		margin-bottom: 5px;
		margin-left: 0px;
		padding-top: 0px;
		padding-left: 0px;
		font-size: 2.4rem;
		line-height: 2.4rem;
	}

	.careers-homepage .bold-text {
		width: 100%;
		font-size: 2.4rem;
		line-height: 2.4rem;
	}

	.careers-homepage .box-paragraph-1 {
		position: relative;
		z-index: auto;
		display: block;
		margin-bottom: 20px;
		padding-right: 0px;
		padding-left: 0px;
		opacity: 1;
		color: #fff;
	}

	.careers-homepage .secondary-button2.about-section.white-button {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.careers-homepage .box-2 {
		width: 50%;
		min-height: 550px;
		height: auto;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.careers-homepage .box-caption-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 550px;
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.careers-homepage .box-caption-3.box2 {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.careers-homepage .bold-text-2 {
		line-height: 2.4rem;
	}

	.careers-homepage .box-paragraph-3 {
		margin-bottom: 20px;
		padding-right: 0px;
		padding-left: 0px;
		opacity: 1;
	}

	.careers-homepage .box-3 {
		width: 50%;
		height: auto;
		min-height: 550px;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.careers-homepage .box-caption-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 20px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.careers-homepage .bold-text-3 {
		font-size: 2.4rem;
	}

	.careers-homepage .box-paragraph-3 {
		margin-bottom: 20px;
		padding-right: 0px;
		padding-left: 0px;
		opacity: 1;
	}

	.careers-homepage .box-4 {
		width: 50%;
		height: auto;
		min-height: 550px;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.careers-homepage .box-caption-3.box-large {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
	}

	.careers-homepage .section-content-3 {
		padding: 3rem 3rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	.careers-homepage .our-firm-section-2 {
		width: 100%;
		margin-bottom: 2rem;
		padding-right: 0rem;
	}

	.careers-homepage .paragraph-18 {
		max-width: none;
		font-size: 1rem;
		line-height: 1.6rem;
	}

	.careers-homepage .scattered-images {
		padding-right: 0px;
		padding-left: 0px;
	}

	.careers-homepage .link-block {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.careers-homepage .div-block-16 {
		width: 100%;
		height: 320px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.careers-homepage .image-23 {
		width: auto;
		height: auto;
		max-width: 100.0354269276495%;
	}

	.careers-homepage .heading-25 {
		padding-left: 0px;
		font-size: 30px;
		line-height: 35px;
		text-align: left;
	}

	.careers-homepage .div-block-17 {
		height: 320px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.careers-homepage .image-24 {
		height: auto;
	}

	.careers-homepage .scattered-images-second-row {
		padding-right: 0px;
		padding-left: 0px;
	}

	.careers-homepage .div-block-20 {
		width: 100%;
		height: 250px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.careers-homepage .div-block-20.block-first.bootom-left {
		height: 320px;
	}

	.careers-homepage .div-block-20.botttom-block {
		height: 320px;
	}

	.careers-homepage .div-block-151 {
		height: auto;
	}

	.careers-homepage .image-22 {
		height: 525px;
	}

	.careers-homepage .testimonial-wrapper {
		position: relative;
		height: auto;
		min-height: auto;
	}

	.careers-homepage .testimonial-column {
		width: 100%;
		min-height: 525px;
		padding: 0rem 1rem 2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.careers-homepage .heading-26 {
		margin-top: 20px;
	}

	.careers-homepage .block-quote-3 {
		margin-bottom: 0rem;
		font-size: 1.2rem;
	}

	.careers-homepage .bold-text-4.text-4 {
		font-size: 2.4rem;
		line-height: 2.4rem;
	}
}

@media screen and (max-width: 767px) {
	.careers-homepage .inside-page-content {
		margin-top: 0px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.careers-homepage .inside-page-header-section {
		height: 500px;
		background-position: 0px 0px, 0px 0px, 50% 50%;
	}

	.careers-homepage .inside-hero-section {
		background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/assets/images/Desktop---1-15.svg');
		background-image: linear-gradient(180deg, transparent, transparent), url('/assets/images/Desktop---1-15.svg');
		background-position: 0px 0px, 0% 50%;
	}

	.careers-homepage .inside-page-container.white-background {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.careers-homepage .content-container.threequarterwidth {
		width: 100%;
	}

	.careers-homepage .breadcrumbs-wrapper {
		margin-top: 0.25rem;
		padding-left: 0rem;
		font-size: 0.85rem;
	}

	.careers-homepage .intro-paragraph {
		font-size: 1.125rem;
	}

	.careers-homepage .box-1 {
		width: 100%;
		height: auto;
		min-height: 380px;
	}

	.careers-homepage .box-caption-1 {
		height: 300px;
		padding-right: 40px;
		padding-left: 40px;
	}

	.careers-homepage .heading-24 {
		margin-bottom: 10px;
	}

	.careers-homepage .box-2 {
		width: 100%;
		min-height: 380px;
		height: auto;
	}

	.careers-homepage .box-caption-3 {
		height: 380px;
		padding-right: 40px;
		padding-left: 40px;
	}

	.careers-homepage .box-3 {
		width: 100%;
		min-height: 380px;
		height: auto;
	}

	.careers-homepage .box-caption-3 {
		height: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}

	.careers-homepage .box-4 {
		width: 100%;
		min-height: 380px;
		height: auto;
	}

	.careers-homepage .box-caption-3.box-large {
		height: 100%;
	}

	.careers-homepage .section-content-3 {
		padding: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.careers-homepage .section-content-3.section2 {
		padding-right: 3rem;
		padding-left: 0rem;
	}

	.careers-homepage .our-firm-section-2 {
		width: 100%;
		margin-bottom: 1rem;
	}

	.careers-homepage .section-heading-4 {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.careers-homepage .paragraph-18 {
		font-size: 1.05rem;
		line-height: 1.5rem;
	}

	.careers-homepage .scattered-images {
		padding: 60px 0px 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.careers-homepage .link-block {
		width: 100%;
		margin-bottom: 40px;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.careers-homepage .image-23 {
		width: 100%;
		height: auto;
		margin-top: -71px;
	}

	.careers-homepage .heading-25 {
		padding-left: 0px;
		text-align: left;
	}

	.careers-homepage .link-block-copy {
		width: 100%;
		margin-top: 0px;
		margin-bottom: 40px;
	}

	.careers-homepage .image-24 {
		width: 200%;
		height: auto;
	}

	.careers-homepage .scattered-images-second-row {
		margin-top: 0px;
		padding: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.careers-homepage .image-5 {
		margin-top: -262px;
	}

	.careers-homepage .image-6 {
		margin-top: -182px;
	}

	.careers-homepage .div-block-151 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.careers-homepage .image-wrapper {
		width: 100%;
		height: 400px;
		margin-left: 0rem;
	}

	.careers-homepage .image-22 {
		height: 400px;
		background-position: 50% 35%;
	}

	.careers-homepage .testimonial-wrapper {
		width: 100%;
		margin-bottom: 0rem;
		padding-right: 40px;
		padding-left: 40px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.careers-homepage .testimonial-column {
		width: 100%;
		margin-bottom: 2rem;
		padding: 0rem;
		border-right-style: none;
		min-height: auto;
	}

	.careers-homepage .block-quote-3 {
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 479px) {
	.careers-homepage .inside-page-container.white-background {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.careers-homepage .breadcrumbs-wrapper {
		margin-top: 0rem;
		padding-left: 0rem;
	}

	.careers-homepage .box-1 {
		min-height: 720px;
	}

	.careers-homepage .box-caption-1 {
		height: 350px;
		padding-top: 0px;
	}

	.careers-homepage .box-caption-1.box-large {
		height: 100%;
		padding-top: 20px;
		padding-right: 15px;
		padding-left: 15px;
	}

	.careers-homepage .heading-24 {
		max-width: 180px;
		margin-top: 0px;
		margin-bottom: 10px;
		padding-top: 0px;
	}

	.careers-homepage .bold-text {
		font-size: 2.2rem;
	}

	.careers-homepage .box-2 {
		min-height: 720px;
	}

	.careers-homepage .box-caption-3 {
		height: 720px;
		padding-top: 20px;
		padding-right: 15px;
		padding-left: 15px;
	}

	.careers-homepage .box-caption-3.box2 {
		padding-right: 15px;
		padding-left: 15px;
	}

	.careers-homepage .bold-text-2.text2 {
		font-size: 2.2rem;
	}

	.careers-homepage .box-3 {
		min-height: 720px;
	}

	.careers-homepage .box-caption-3 {
		height: 100%;
		padding-top: 20px;
		padding-right: 15px;
		padding-left: 15px;
	}

	.careers-homepage .bold-text-3 {
		font-size: 2.2rem;
	}

	.careers-homepage .box-4 {
		min-height: 720px;
		background-position: 50% 50%;
	}

	.careers-homepage .box-caption-3.box-large {
		height: 100%;
	}

	.careers-homepage .box-caption-3.box-large.box-left-bottom {
		padding-right: 15px;
		padding-left: 15px;
	}

	.careers-homepage .section-content-3 {
		padding: 2rem 1.5rem;
	}

	.careers-homepage .section-heading-4 {
		font-size: 2rem;
		line-height: 2.35rem;
	}

	.careers-homepage .paragraph-18 {
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 300;
	}

	.careers-homepage .scattered-images {
		padding-top: 10px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.careers-homepage .image-23 {
		width: 100%;
		height: auto;
		margin-top: 0px;
	}

	.careers-homepage .image-5 {
		margin-top: 0px;
	}

	.careers-homepage .image-wrapper {
		margin-bottom: 1rem;
	}

	.careers-homepage .testimonial-wrapper {
		margin-bottom: 1rem;
		padding-right: 20px;
		padding-left: 20px;
	}

	.careers-homepage .testimonial-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1rem;
		padding-top: 0rem;
		padding-bottom: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.careers-homepage .quote-block {
		margin-bottom: 0px;
	}

	.careers-homepage .block-quote-3 {
		margin-bottom: 0rem;
		font-size: 1.1rem;
		line-height: 1.8rem;
		text-align: left;
	}

	.careers-homepage .bold-text-4 {
		font-size: 2.2rem;
		width: 48%;
		max-width: 180px;
	}
}
